import _ from "lodash";

class PrintfulCatalogProduct {
  variants = [];

  constructor(props) {
    Object.assign(this, props);
  }

  getColorOptions() {
    const mappedColors = [];
    const colors = _.uniq(this.variants.map((v) => v.color_code));

    for (const color_code of colors) {
      mappedColors.push({
        color_code,
        variants: this.variants.filter((v) => v.color_code === color_code),
      });
    }

    return mappedColors;
  }

  getDefaultVariants() {
    let _variants = [];

    if (this.variants.findIndex((v) => v.color === "White") >= 0) {
      _variants = this.variants.filter((v) => v.color === "White");
    } else if (this.variants.findIndex((v) => v.color === "Black") >= 0) {
      _variants = this.variants.filter((v) => v.color === "Black");
    } else {
      _variants = this.variants;
    }

    return _variants.map((v) => v.id);
  }

  getSampleParams({
    variant_ids,
    img,
    printFile,
    o_left,
    o_top,
    o_width,
    o_height,
  }) {
    return new Promise(async (resolve, reject) => {
      const area_width = printFile.width;
      const area_height = printFile.height;
      const wideLoad = printFile.width > printFile.height;

      let width = o_width;
      let height = o_height;
      let top = o_top !== undefined ? o_top : printFile.height * 0.1;
      let left = o_left !== undefined ? o_left : printFile.width * 0.1;
      let percent = 1;

      console.log(printFile);

      if (!o_width) {
        percent = wideLoad ? 1 : 0.8;
        await new Promise((_resolve) => {
          const image = new Image();

          image.onload = function () {
            width = image.width;
            height = image.height;
            _resolve(image);
          };

          image.src = img;
        });
      }

      const ratio = wideLoad
        ? (area_height * percent) / height
        : (area_width * percent) / width;

      if (wideLoad) {
        height = height * ratio;

        width *= ratio;

        left = printFile.width / 2 - width / 2;

        top = 0;
      } else {
        width = width * ratio;

        height *= ratio;
      }

      if (o_height && o_width) {
        width = o_width;
        height = o_height;
      }

      console.log(`DIMS: x:${left}, y:${top}, w:${width}, h:${height}`, o_left);

      const params = {
        variant_ids,
        option_groups: [],
        format: "png",
        files: [],
      };

      switch (this.type_name.toLowerCase()) {
        case "hoodie":
        case "t-shirt":
          params.files.push({
            image_url: img,
            url: img,
            placement: "front",
            position: {
              area_width,
              area_height,
              width,
              height,
              left,
              top,
            },
          });
          resolve(params);
          break;
        case "iphone case":
          params.variant_ids = params.variant_ids.slice(0, 1);
          params.files.push({
            image_url: img,
            url: img,
            placement: "default",
            position: {
              area_width,
              area_height,
              width,
              height,
              left,
              top,
            },
          });
          resolve(params);
          break;
        case "mug":
          params.variant_ids = params.variant_ids.slice(0, 1);
          params.placement = [];
          params.files.push({
            image_url: img,
            url: img,
            placement: "default",
            position: {
              area_width,
              area_height,
              width,
              height,
              left,
              top,
            },
          });
          resolve(params);
          break;
        case "enhanced matte paper poster (in)":
        case "enhanced matte paper framed poster (in)":
          params.files.push({
            image_url: img,
            url: img,
            placement: "default",
            position: {
              area_width,
              area_height,
              width,
              height,
              left,
              top,
            },
          });
          resolve(params);
          break;
        case "hat":
          params.files.push({
            image_url: img,
            url: img,
            placement: "embroidery_front",
            position: {
              area_width,
              area_height,
              width,
              height,
              left,
              top,
            },
          });
          resolve(params);
          break;
      }
    });
  }
}

export default PrintfulCatalogProduct;
