import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import React from "react";
import { Button } from "@mui/material";
import { makeSelectThreadBalance } from "../App/selectors";
import { useHistory } from "react-router-dom";
import { makeSelectConnectedWallet } from "../ConnectedWalletWrapper/selectors";

const ThreadTokenBalanceButton = ({ balance, connectedWallet }) => {
  const history = useHistory();

  if (!connectedWallet) return null;

  return (
    <Button
      variant="outlined"
      fullWidth
      onClick={() => {
        history.push("/redeem-thread-tokens");
      }}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <img
        alt="thread"
        style={{ height: 20 }}
        src={require("../../images/thread.png")}
      />
      {balance || "0"}
    </Button>
  );
};

const mapStateToProps = createStructuredSelector({
  balance: makeSelectThreadBalance(),

  connectedWallet: makeSelectConnectedWallet(),
});

export default connect(mapStateToProps, null)(ThreadTokenBalanceButton);
