import { put, takeLatest, all, select } from "redux-saga/effects";
import { makeSelectWeb3 } from "../Web3Wrapper/selectors";
import { makeSelectConnectedWallet } from "../ConnectedWalletWrapper/selectors";
import { setEthBalance, setThreadBalance } from "./actions";
import { FETCH_ETH_BALANCE, FETCH_THREAD_BALANCE } from "./constants";
import { makeSelectThreadToken } from "../ContractsWrapper/selectors";

export function* fetchEthBalanceSaga() {
  const web3 = yield select(makeSelectWeb3());
  const connectedWallet = yield select(makeSelectConnectedWallet());

  if (!web3 || !connectedWallet) {
    return;
  }

  try {
    const _bal = yield web3.eth.getBalance(connectedWallet);

    yield put(setEthBalance((_bal / 10 ** 18).toFixed(2)));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchThreadBalanceSaga() {
  const threadToken = yield select(makeSelectThreadToken());
  const connectedWallet = yield select(makeSelectConnectedWallet());

  if (!threadToken || !connectedWallet) {
    return;
  }

  try {
    const res = yield threadToken.methods.balanceOf(connectedWallet).call();

    yield put(setThreadBalance(res / 100));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchEthBalanceWatcher() {
  yield takeLatest(FETCH_ETH_BALANCE, fetchEthBalanceSaga);
}

export function* fetchThreadBalanceWatcher() {
  yield takeLatest(FETCH_THREAD_BALANCE, fetchThreadBalanceSaga);
}

export default function* rootAppSaga() {
  yield all([fetchEthBalanceWatcher(), fetchThreadBalanceWatcher()]);
}
