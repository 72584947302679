import { CONNECT_WEB3, SET_CHAIN, SET_PROVIDER, SET_WEB3 } from "./constants";

export const setWeb3 = (web3) => ({
  type: SET_WEB3,
  web3,
});

export const connectWeb3 = () => ({
  type: CONNECT_WEB3,
});

export const setProvider = (provider) => ({
  type: SET_PROVIDER,

  provider,
});

export const setChain = (chainId) => ({
  type: SET_CHAIN,

  chainId,
});
