import { put, takeLatest, all } from "redux-saga/effects";
import Web3 from "web3";
import { CONNECT_WEB3 } from "./constants";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { setChain, setProvider, setWeb3 } from "./actions";
import { setConnectedWallet } from "../ConnectedWalletWrapper/actions";

export function* connectWeb3Saga() {
  try {
    const web3Modal = new Web3Modal({
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          rpc: {
            43114: "https://api.avax.network/ext/bc/C/rpc",
          },
          options: {
            infuraId: "0a44e4ac8daf4b87bd2f4b48ed7a947a",
            qrcodeModalOptions: {
              mobileLinks: [
                "rainbow",
                "metamask",
                "argent",
                "trust",
                "imtoken",
                "pillar",
              ],
            },
          },
        },
      },
    });

    const provider = yield web3Modal.connect();

    yield put(setProvider(provider));

    const web3 = new Web3(provider);

    const accounts = yield web3.eth.getAccounts();

    const chainId = yield web3.eth.getChainId();

    yield put(setConnectedWallet(accounts[0]));

    yield put(setChain("0x" + chainId.toString(16)));

    yield put(setWeb3(web3));
  } catch (err) {
    console.log(err);
  }
}

export function* connectWeb3Watcher() {
  yield takeLatest(CONNECT_WEB3, connectWeb3Saga);
}

export default function* rootAccountSaga() {
  yield all([connectWeb3Watcher()]);
}
