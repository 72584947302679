import {
  Drawer,
  List,
  ListItemButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { makeSelectConnectedWallet } from "../ConnectedWalletWrapper/selectors";
import { makeSelectThreadDaoContract } from "../ContractsWrapper/selectors";
import { connect } from "react-redux";
import Snow from "../../components/Snow";
import ConnectedWalletButton from "../ConnectedWalletButton";
import ThreadTokenBalanceButton from "../ThreadTokenBalanceButton";

const MainDrawer = ({
  isDrawerOpen,
  toggleClose,
  connectedWallet,
  threadDaoInstance,
}) => {
  const history = useHistory();
  const [isVendor, setIsVendor] = useState(false);

  useEffect(() => {
    (async () => {
      if (threadDaoInstance && connectedWallet) {
        setIsVendor(
          (await threadDaoInstance.methods.getVendor().call()) ===
            connectedWallet
        );
      }
    })();
  }, [threadDaoInstance, connectedWallet]);

  return (
    <React.Fragment key="drawer">
      <Drawer
        style={{ zIndex: 999999 }}
        anchor={"right"}
        open={isDrawerOpen}
        onClose={toggleClose}
      >
        <div id="drawer" style={localStyles.root}>
          <Toolbar style={{ backgroundColor: "transparent" }} />
          <div style={{ padding: "0 16px" }}>
            <ConnectedWalletButton connectedWallet={connectedWallet} />
            <br />
            <br />
            <ThreadTokenBalanceButton connectedWallet={connectedWallet} />
          </div>
          <List>
            <ListItemButton
              onClick={() => {
                toggleClose();
                history.push("/order-history");
              }}
            >
              <Typography>Order History</Typography>
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                toggleClose();
                history.push("/create-product/select-nft");
              }}
            >
              <Typography>Create Product</Typography>
            </ListItemButton>
            {isVendor && (
              <ListItemButton
                onClick={() => {
                  toggleClose();
                  history.push("/publish");
                }}
              >
                <Typography>Publish Product</Typography>
              </ListItemButton>
            )}
            {isVendor && (
              <ListItemButton
                onClick={() => {
                  toggleClose();
                  history.push("/add-payment-token");
                }}
              >
                <Typography>Add Payment Processor</Typography>
              </ListItemButton>
            )}
            {isVendor && (
              <ListItemButton
                onClick={() => {
                  toggleClose();
                  history.push("/pay-vendor");
                }}
              >
                <Typography>Pay Vendor</Typography>
              </ListItemButton>
            )}
          </List>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

const localStyles = {
  root: {
    width: 300,
    backgroundColor: "rgb(36 18 46)",
    height: "100%",
  },
};

const mapStateToProps = createStructuredSelector({
  connectedWallet: makeSelectConnectedWallet(),

  threadDaoInstance: makeSelectThreadDaoContract(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainDrawer);
