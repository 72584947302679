import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { createStructuredSelector } from "reselect";
import { makeSelectConnectedWallet } from "../../containers/ConnectedWalletWrapper/selectors";
import { connect } from "react-redux";
import {
  makeSelectChainId,
  makeSelectWeb3,
} from "../../containers/Web3Wrapper/selectors";
import { connectWeb3 } from "../../containers/Web3Wrapper/actions";
import MoralisClient from "../../clients/MoralisClient";
import NFTCard from "../../containers/NFTCard";
import { makeSelectThreadswap721 } from "../../containers/ContractsWrapper/selectors";
import { Link } from "react-router-dom";
import CreateProductStepper from "../../containers/CreateProductStepper";

const CreateProductFromNFTScreen = ({
  web3,
  connectedWallet,
  chainId,
  dispatch,
  threadswap721,
}) => {
  const [nfts, setNFTS] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (connectedWallet && web3 && threadswap721) loadNFTSAsync();
  }, [connectedWallet, web3, chainId, threadswap721]);

  const loadNFTSAsync = async () => {
    setLoading(true);

    const mRes = await MoralisClient.fetchWalletNFTSAsync({
      address: connectedWallet,
      network: chainId,
    });

    setLoading(false);

    setNFTS(mRes);
  };

  return (
    <Container>
      <CreateProductStepper activeStep={0} />
      {loading && <LinearProgress />}
      {connectedWallet && !loading && nfts.length === 0 && (
        <h3>You got nothin.</h3>
      )}
      <br />
      <Grid spacing={2} container style={{ justifyContent: "center" }}>
        {!loading && (
          <Grid xs={12} sm={3} item>
            <Card elevation={0} style={{ backgroundColor: "transparent" }}>
              <Link to="/create-nft">
                <CardMedia
                  image={require("../../images/create_nft.png")}
                  height={271}
                  style={{
                    height: 271,
                    overflow: "hidden",
                    borderRadius: 13,
                    backgroundSize: "60%",
                  }}
                />
              </Link>
              <CardContent>
                <Typography color="white">CREATE A NEW NFT</Typography>
              </CardContent>
              <CardActions>
                <Link style={{ width: "100%" }} to="/create-nft">
                  <Button variant="outlined" fullWidth>
                    CREATE
                  </Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        )}
        {!connectedWallet && (
          <div className="nft-or">
            <Typography variant="h5">OR</Typography>
          </div>
        )}
        {!connectedWallet && (
          <Grid xs={12} sm={3} item>
            <Card
              onClick={() => {
                dispatch(connectWeb3());
              }}
              elevation={0}
              style={{ backgroundColor: "transparent" }}
            >
              <CardMedia
                image={require("../../images/wallet.png")}
                height={271}
                style={{
                  height: 271,
                  overflow: "hidden",
                  borderRadius: 13,
                  backgroundSize: "60%",
                }}
              />
              <CardContent>
                <Typography color="white">LOAD YOUR NFTS</Typography>
              </CardContent>
              <CardActions>
                <Button
                  onClick={() => {
                    dispatch(connectWeb3());
                  }}
                  variant="outlined"
                  fullWidth
                >
                  CONNECT WALLET
                </Button>
              </CardActions>
            </Card>
          </Grid>
        )}
        {nfts.map((nft) => (
          <Grid key={nft.token_uri} xs={12} sm={3} item>
            <NFTCard metadata={nft} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  connectedWallet: makeSelectConnectedWallet(),

  web3: makeSelectWeb3(),

  chainId: makeSelectChainId(),

  threadswap721: makeSelectThreadswap721(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProductFromNFTScreen);
