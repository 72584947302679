import { fromJS } from "immutable";
import { SET_THREAD_BALANCE, SET_ETH_BALANCE } from "./constants";

const initialState = fromJS({
  threadBalance: 0,
  ethBalance: 0,
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ETH_BALANCE:
      return {
        ...state,
        ethBalance: action.ethBalance,
      };

    case SET_THREAD_BALANCE:
      return {
        ...state,
        threadBalance: action.threadBalance,
      };

    default:
      return state;
  }
}

export { appReducer };
