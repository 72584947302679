import { fromJS } from "immutable";
import {
  SET_LOADING_PRODUCT,
  SET_PRODUCT,
  SET_PAYMENT_PROCESSOR,
  SET_PROCESSING_ORDER,
} from "./constants";

const initialState = fromJS({
  loadingProduct: false,
  paymentProcessor: null,
  product: null,
  processingOrder: false,
});

function productPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PAYMENT_PROCESSOR:
      return {
        ...state,
        paymentProcessor: action.paymentProcessor,
      };

    case SET_PROCESSING_ORDER:
      return {
        ...state,
        processingOrder: action.processingOrder,
      };

    case SET_PRODUCT:
      return {
        ...state,
        product: action.product,
      };

    case SET_LOADING_PRODUCT:
      return {
        ...state,
        loadingProduct: action.loadingProduct,
      };

    default:
      return state;
  }
}

export { productPageReducer };
