import React, { useEffect, useRef, useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Rnd } from "react-rnd";

const TransformArtworkDialog = ({
  printFile,
  artwork,
  onComplete,
  onClose,
}) => {
  const ratio = useRef(printFile.height / printFile.width).current;
  const imageRatio = useRef(0);
  const [params, setParams] = useState({
    x: 0,
    y: 0,
    width: 500,
    height: 500 * ratio,
  });

  useEffect(() => {
    const image = new Image();

    image.onload = function () {
      imageRatio.current = image.height / image.width;
      setParams({
        ...params,
        height: 500 * imageRatio.current,
      });
    };

    image.src = artwork;
  }, [artwork]);

  const { x, y, width, height } = params;

  return (
    <Dialog id="transform-artwork" fullScreen onClose={onClose} open>
      <DialogTitle style={{ display: "flex", flexDirection: "row" }}>
        EDIT ARTWORK <div style={{ flexGrow: 1 }} />
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <div style={{ width: 35 }} />
        <Button
          variant="contained"
          onClick={() => {
            onComplete({
              x: x * (printFile.width / 500),
              y: y * (printFile.height / (500 * ratio)),
              width: width * (printFile.width / 500),
              height: height * (printFile.height / (500 * ratio)),
            });
          }}
        >
          APPLY CHANGES
        </Button>
      </DialogTitle>
      <DialogContent className="content">
        <div className="artwork-container" style={{ height: 500 * ratio }}>
          <Rnd
            lockAspectRatio
            style={{ overflow: "hidden", backgroundColor: "#c2c2c2" }}
            bounds="parent"
            default={{
              x,
              y,
              width,
              height,
            }}
            position={{
              x,
              y,
            }}
            size={{
              width,
              height,
            }}
            onDragStop={(e, d) => {
              setParams({
                ...params,
                x: d.x,
                y: d.y,
              });
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              setParams({
                x: position.x,
                y: position.y,
                width: width + delta.width,
                height: height + delta.height,
              });
            }}
          >
            <img
              alt="Edit image"
              draggable={false}
              width="100%"
              src={artwork}
            />
          </Rnd>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TransformArtworkDialog;
