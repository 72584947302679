import React, { useEffect } from "react";
import { CircularProgress, Container, Grid } from "@mui/material";
import Helmet from "react-helmet";
import ProductCard from "../../containers/ProductCard";
import { connect } from "react-redux";
import { loadProduct, setProduct } from "./actions";
import { createStructuredSelector } from "reselect";
import { makeSelectProduct } from "./selectors";
import HowItWorksRow from "../../containers/HowItWorksRow";

const ProductSwapScreen = ({ handle = "ngmi", dispatch, product }) => {
  useEffect(() => {
    dispatch(loadProduct(handle));

    return () => {
      dispatch(setProduct(null));
    };
  }, [handle]);

  if (!product)
    return (
      <div
        className="centered"
        style={{
          height: "calc(100vh - 200px)",
        }}
      >
        <CircularProgress variant="indeterminate" />
      </div>
    );

  console.log(product);

  return (
    <Container>
      <Helmet>
        <title>{product.name}</title>
        <meta name="description" content={product.name} />
        <meta property="og:title" content={product.name} />
        <meta property="og:description" content={product.name} />
        <meta property="og:image" content={product.thumbnail_url} />
        <meta
          property="og:url"
          content={`https://threaddao.com/products/${product.id}`}
        />
        <meta name="twitter:title" content={product.name} />
        <meta name="twitter:description" content={product.name} />
        <meta name="twitter:image" content={product.thumbnail_url} />
        <meta name="twitter:card" content={product.thumbnail_url} />
      </Helmet>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ paddingBottom: { xs: "24px", sm: 0 } }}>
          <img alt={product.name} src={product.thumbnail_url} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ProductCard product={product} />
        </Grid>
      </Grid>
      <br />
      <br />
      <HowItWorksRow />
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  product: makeSelectProduct(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSwapScreen);
