import { createSelector } from "reselect";

const selectPaymentProcessorsCore = (state) => state.paymentProcessors;

const makeSelectPaymentProcessors = () =>
  createSelector(
    selectPaymentProcessorsCore,

    (contractState) => contractState.paymentProcessors
  );

export { makeSelectPaymentProcessors };
