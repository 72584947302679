import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const { Grid, Typography } = require("@mui/material");
const React = require("react");
const HowItWorksRow = ({ title }) => (
  <div id="hiw-section">
    <Typography color="primary" variant="h3" className="textShadow">
      {title || "HOW IT WORKS"}
    </Typography>
    <Typography paragraph>Create and sell products from your NFTs!</Typography>
    <br />
    <Grid container spacing={6}>
      <Grid item xs={12} sm={4}>
        <div
          className="hp-hiw-image"
          style={{
            backgroundImage: `url(${require("../../images/hiw_1.png")})`,
          }}
        />
        <div className="step-number">
          <Typography variant="h4" className="textShadowGrey">
            1
          </Typography>
        </div>
        <Typography>Select or create an NFT you own.</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <div
          className="hp-hiw-image"
          style={{
            backgroundImage: `url(${require("../../images/hiw_2.png")})`,
          }}
        />
        <div className="step-number">
          <Typography variant="h4" className="textShadowGrey">
            2
          </Typography>
        </div>
        <Typography>Create your product.</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <div
          className="hp-hiw-image"
          style={{
            backgroundImage: `url(${require("../../images/hiw_3.png")})`,
          }}
        />
        <div className="step-number">
          <Typography variant="h4" className="textShadowGrey">
            3
          </Typography>
        </div>
        <Typography>Buy or sell your new item.</Typography>
      </Grid>
    </Grid>
    <br />
    <br />
    <div style={{ width: "100%", maxWidth: 500, margin: "0 auto" }}>
      <Link to="/create-product/select-nft">
        <Button
          style={{ boxShadow: "#757575 5px 4px" }}
          fullWidth
          variant="contained"
          size="large"
        >
          <Typography variant="h4" style={{ fontWeight: "bold" }}>
            GET STARTED
          </Typography>
        </Button>
      </Link>
    </div>
  </div>
);

export default HowItWorksRow;
