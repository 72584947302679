import { createStructuredSelector } from "reselect";
import { makeSelectThreadDaoContract } from "../../containers/ContractsWrapper/selectors";
import { makeSelectConnectedWallet } from "../../containers/ConnectedWalletWrapper/selectors";
import { makeSelectWeb3 } from "../../containers/Web3Wrapper/selectors";
import { connect } from "react-redux";
import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import PrintfulClient from "../../clients/PrintfulClient";
import GridProduct from "../../containers/GridProduct";

const WalletShopScreen = ({ connectedWallet, threadDaoInstance }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    (async () => {
      if (!threadDaoInstance) return;

      const res = await threadDaoInstance.methods
        .productsByAddress(connectedWallet)
        .call();

      const _products = await Promise.all(
        res.map((p) => PrintfulClient.fetchProductById(p.sku))
      );

      setProducts(
        _products
          .filter((res) => res.code === 200)
          .map((res) => res.result.sync_product)
      );

      // console.log(_products);
      // console.log(res);
    })();
  }, [threadDaoInstance]);

  console.log(products);

  return (
    <Container>
      <Grid container>
        {products.map((product) => (
          <Grid item xs={3} key={product.id}>
            <GridProduct product={product} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  threadDaoInstance: makeSelectThreadDaoContract(),

  connectedWallet: makeSelectConnectedWallet(),

  web3: makeSelectWeb3(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletShopScreen);
