import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Grid,
  Typography,
  FormControl,
  TextField,
  FormHelperText,
  CardActions,
  Button,
} from "@mui/material";
import { createStructuredSelector } from "reselect";
import { makeSelectConnectedWallet } from "../../containers/ConnectedWalletWrapper/selectors";
import { makeSelectWeb3 } from "../../containers/Web3Wrapper/selectors";
import {
  makeSelectThreadDaoContract,
  makeSelectThreadToken,
} from "../../containers/ContractsWrapper/selectors";
import { connect } from "react-redux";
import { ArrowDownward } from "@mui/icons-material";

const RedeemThreadTokensScreen = ({
  threadToken,
  connectedWallet,
  threadSwapInstance,
}) => {
  const [working, setWorking] = useState(false);
  const [balance, setBalance] = useState(0);
  const [toEthAmount, setToEthAmount] = useState(0);

  useEffect(() => {
    if (!threadToken) return;

    (async () => {
      const res = await threadToken.methods.balanceOf(connectedWallet).call();
      setBalance(res / 100);
    })();
  }, [threadToken, connectedWallet]);

  useEffect(() => {
    if (!threadSwapInstance) return;
    (async () => {
      const res = await threadSwapInstance.methods
        .getLatestNativePriceInUSD()
        .call();

      setToEthAmount(parseFloat(balance) / (res / 10 ** 8));
    })();
  }, [balance, threadSwapInstance]);

  const redeemForEthAsync = async () => {
    setWorking(true);
    await threadToken.methods
      .approve(threadSwapInstance._address, Math.round(balance * 100))
      .send({ from: connectedWallet });

    await threadSwapInstance.methods
      .redeemThreadTokens(Math.round(balance * 100))
      .send({ from: connectedWallet });
    setWorking(false);
  };

  return (
    <Container id="app-container">
      <Card
        elevation={0}
        className="product-card"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="asset-row">
          <Grid container>
            <Grid className="top-row" item xs={12} sm={6}>
              <span className="image-wrapper">
                <div style={{ width: 64 }}>
                  <img
                    style={{ width: 64 }}
                    alt="AVAX"
                    src={require("../../images/thread.png")}
                  />
                </div>
              </span>
              <div className="details">
                <Typography variant="caption" style={{ color: "#6f6f6f" }}>
                  Swap
                </Typography>
                <div style={{ width: "100%", display: "flex" }}>
                  <Typography
                    style={{ color: "white", whiteSpace: "nowrap" }}
                    variant="h5"
                  >
                    Thread Tokens{" "}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid style={{ justifyContent: "flex-end" }} item xs={12} sm={6}>
              <FormControl style={{ textAlign: "right" }}>
                <TextField
                  inputProps={{ readOnly: true }}
                  readOnly="readOnly"
                  aria-readonly="true"
                  className="input"
                  value={balance}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <div style={{ height: 40, paddingLeft: 20, position: "relative" }}>
          <div className="arrow-wrap">
            <ArrowDownward style={{ color: "white" }} />
          </div>
        </div>
        <div className="asset-row">
          <Grid container>
            <Grid className="top-row" item xs={12} sm={6}>
              <span className="image-wrapper">
                <div style={{ width: 64 }}>
                  <img
                    style={{ width: 64 }}
                    alt="AVAX"
                    src={require("../../images/avax.jpeg")}
                  />
                </div>
              </span>
              <div className="details">
                <Typography variant="caption" style={{ color: "#6f6f6f" }}>
                  For
                </Typography>
                <div style={{ width: "100%", display: "flex" }}>
                  <Typography
                    style={{ color: "white", whiteSpace: "nowrap" }}
                    variant="h5"
                  >
                    AVAX
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid style={{ justifyContent: "flex-end" }} item xs={12} sm={6}>
              <FormControl style={{ textAlign: "right" }}>
                <TextField
                  inputProps={{ readOnly: true }}
                  readOnly="readOnly"
                  aria-readonly="true"
                  className="input"
                  value={toEthAmount}
                  variant="outlined"
                />
                <FormHelperText
                  id="filled-weight-helper-text"
                  style={{
                    color: "#6f6f6f",
                    position: "absolute",
                    bottom: -20,
                    right: 0,
                  }}
                >
                  ${balance}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <br />
        <br />
        <CardActions>
          <Button
            disabled={working || !connectedWallet}
            onClick={redeemForEthAsync}
            size="large"
            fullWidth
            variant="contained"
          >
            REDEEM FOR AVAX
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  connectedWallet: makeSelectConnectedWallet(),

  web3: makeSelectWeb3(),

  threadToken: makeSelectThreadToken(),

  threadSwapInstance: makeSelectThreadDaoContract(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedeemThreadTokensScreen);
