import { createSelector } from "reselect";

const selectApp = (state) => state.app;

const makeSelectEthBalance = () =>
  createSelector(
    selectApp,

    (appState) => appState.ethBalance
  );

const makeSelectThreadBalance = () =>
  createSelector(
    selectApp,

    (appState) => appState.threadBalance
  );

export { makeSelectThreadBalance, makeSelectEthBalance };
