import React, { useEffect, useState } from "react";
import { createStructuredSelector } from "reselect";
import { makeSelectThreadDaoContract } from "../../containers/ContractsWrapper/selectors";
import { makeSelectConnectedWallet } from "../../containers/ConnectedWalletWrapper/selectors";
import { connect } from "react-redux";
import {
  Button,
  Container,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

const OrderHistoryPage = ({ connectedWallet, threadDaoInstance }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (connectedWallet && threadDaoInstance) {
      (async () => {
        setLoading(true);

        const res = await threadDaoInstance.methods
          .getWalletsOrders(connectedWallet)
          .call();

        console.log("tdao", res);
        setOrders(res);
        setLoading(false);
      })();
    }
  }, [connectedWallet, threadDaoInstance]);

  return (
    <Container style={{ textAlign: "left" }}>
      <Typography color="primary" paragraph variant="h5">
        Orders
      </Typography>
      {loading && <LinearProgress size="small" color="primary" />}
      <Table style={{ whiteSpace: "nowrap" }}>
        <TableBody>
          {orders.map((order) => (
            <TableRow key={order.orderId}>
              <TableCell style={{ paddingLeft: 0 }}>
                <Typography color="primary">{order.orderId}</Typography>
              </TableCell>
              <TableCell>
                <Typography color="primary">{order.hash}</Typography>
              </TableCell>
              <TableCell>
                <Typography color="primary">
                  {/*{order.line_items[0].name}*/}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="primary">
                  {/*{order.fulfillment_status}*/}
                </Typography>
              </TableCell>
              <TableCell style={{ width: "100%" }} />
              <TableCell>
                <a href={order.order_status_url} target="_blank">
                  <Button>View Status</Button>
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  threadDaoInstance: makeSelectThreadDaoContract(),

  connectedWallet: makeSelectConnectedWallet(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistoryPage);
