import { combineReducers } from "redux";
import { contractsReducer } from "../containers/ContractsWrapper/reducers";
import { connectedWalletReducer } from "../containers/ConnectedWalletWrapper/reducers";
import { web3Reducer } from "../containers/Web3Wrapper/reducers";
import { lightBoxReducer } from "../containers/LightBox/reducers";
import { productPageReducer } from "../screens/ProductSwapScreen/reducers";
import { paymentProcessorsReducer } from "../containers/PaymentProcessorsWrapper/reducers";
import { appReducer } from "../containers/App/reducers";

const initialState = {};

const reducers = function (state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
};

export default combineReducers({
  app: appReducer,
  lightBox: lightBoxReducer,
  contracts: contractsReducer,
  connectedWallet: connectedWalletReducer,
  web3: web3Reducer,
  productPage: productPageReducer,
  paymentProcessors: paymentProcessorsReducer,
  reducers,
});
