import { createSelector } from "reselect";

const selectLightBox = (state) => state.lightBox;

const makeSelectLightBoxImages = () =>
  createSelector(
    selectLightBox,

    (lightBox) => lightBox.images
  );

export { makeSelectLightBoxImages };
