import React from "react";
import { Container } from "@mui/material";
import StoreGrid from "../../containers/StoreGrid";
import { useHistory } from "react-router-dom";

const ShopScreen = () => {
  const history = useHistory();

  return (
    <Container>
      <div style={{ height: 30 }} />
      <StoreGrid
        limit={100}
        handleItemSelected={(item) => {
          history.push(`/products/${item.handle}`);
        }}
      />
      <div style={{ height: 30 }} />
    </Container>
  );
};

export default ShopScreen;
