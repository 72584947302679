class Cloudinary {
  static uploadImageAsync(file) {
    const formData = new FormData();

    formData.append("file", file);

    formData.append("upload_preset", "docs_upload_example_us_preset");

    return new Promise(async (resolve, reject) => {
      await fetch("https://api.cloudinary.com/v1_1/demo/image/upload", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then(async ({ url }) => {
          resolve(url);
        })
        .catch(reject);
    });
  }
}

export default Cloudinary;
