import React, { useRef, useState } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { makeSelectThreadswap721 } from "../../containers/ContractsWrapper/selectors";
import { makeSelectWeb3 } from "../../containers/Web3Wrapper/selectors";
import { makeSelectConnectedWallet } from "../../containers/ConnectedWalletWrapper/selectors";
import { Image as MUImage } from "@mui/icons-material";
import FunctionClient from "../../clients/FunctionClient";
import { connectWeb3 } from "../../containers/Web3Wrapper/actions";
import { useHistory } from "react-router-dom";
import Cloudinary from "../../clients/Cloudinary";
import CreateProductStepper from "../../containers/CreateProductStepper";

const CreateNFTScreen = ({
  threadswap721,
  web3,
  connectedWallet,
  dispatch,
}) => {
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [working, setWorking] = useState(false);
  const [paddingTop, setPaddingTop] = useState(133.333);

  const readerResults = useRef(null);

  const history = useHistory();

  const createNFT = async () => {
    if (!connectedWallet) {
      dispatch(connectWeb3());
      return;
    }

    if (!file) {
      alert("No artwork submitted");
      return;
    }

    try {
      setWorking(true);

      const res = await FunctionClient.updateNFTImage(file);

      web3.eth
        .sendTransaction({
          from: connectedWallet,
          to: threadswap721._address,
          data: threadswap721.methods.mint(res.url).encodeABI(),
        })
        .on("receipt", () => {
          setWorking(false);
          history.push({
            pathname: "/create-product/select-product",
            state: {
              ...history.location.state,
              nft: {
                token_address: threadswap721._address,
                token_id: "69",
                block_number_minted: "9305848",
                owner_of: connectedWallet,
                block_number: "",
                amount: "1",
                contract_type: "ERC721",
                name: "Threadswap Merch Artwork",
                symbol: "THREAD",
                token_uri:
                  "https://ipfs.moralis.io:2053/ipfs/QmUxtirV7ddTjvNgD1EkgChWZy65ZHYYdMzApTjRU9Tq5Q/4",
                metadata: null,
                network: "0xa86a",
                image: res.url,
                external_url: "https://threadswap.com/",
                description: "Create merch from NFTs",
              },
            },
          });
        });
    } catch (err) {
      setWorking(false);
      console.log(err);
    }
  };

  const handleFileDropped = async (e) => {
    try {
      setFile(e.target.files[0]);

      const reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);

      reader.onloadend = function () {
        readerResults.current = reader.result;

        setImage(reader.result);

        const image = new Image();

        image.onload = function () {
          setPaddingTop((image.height / image.width) * 100);
        };

        image.src = reader.result;
      };

      const url = await Cloudinary.uploadImageAsync(e.target.files[0]);

      setImage(url);
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <Container maxWidth="md">
      <CreateProductStepper createMode activeStep={0} />
      <Grid container justifyContent="center" spacing={6}>
        <Grid justifyContent="center" item xs={12} sm={6}>
          <div
            className="image-uploader-container"
            style={{
              backgroundImage: `url(${image})`,
              paddingTop: `${paddingTop}%`,
            }}
          >
            <input type="file" onChange={handleFileDropped} />
            {!image && (
              <div className="new-nft-empty">
                <MUImage style={{ fontSize: 88 }} />
                <Typography>Drop Artwork</Typography>
              </div>
            )}
          </div>
          <br />
          {!working && (
            <Button fullWidth onClick={createNFT} variant="contained">
              {!connectedWallet ? "CONNECT WALLET" : "Create NFT"}
            </Button>
          )}
          {working && (
            <Button fullWidth disabled variant="contained">
              Creating NFT
            </Button>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  web3: makeSelectWeb3(),

  threadswap721: makeSelectThreadswap721(),

  connectedWallet: makeSelectConnectedWallet(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNFTScreen);
