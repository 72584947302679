import { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeSelectProvider, makeSelectWeb3 } from "./selectors";
import { setChain } from "./actions";
import { setConnectedWallet } from "../ConnectedWalletWrapper/actions";
import { fetchEthBalance } from "../App/actions";

const Web3Wrapper = ({ children, web3, dispatch, provider }) => {
  useEffect(() => {
    if (!provider) return;

    provider.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        dispatch(setConnectedWallet(accounts[0]));
        dispatch(fetchEthBalance());
      }
    });

    provider.on("chainChanged", (chainId) => {
      dispatch(setChain(chainId));
      dispatch(fetchEthBalance());
    });
  }, [provider]);

  return children;
};

const mapStateToProps = createStructuredSelector({
  web3: makeSelectWeb3(),

  provider: makeSelectProvider(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Web3Wrapper);
