class MoralisClient {
  static fetch({ endpoint, method = "GET" }) {
    return fetch(`https://deep-index.moralis.io/api/v2/${endpoint}`, {
      method,
      headers: {
        accept: "application/json",
        "X-API-Key":
          "IaKEmLXOXiZ85bnDrObJHOKD88oLUQ3sRxJsOEFddwx2Ymg5ISdbVMgnBovcQIZ8",
      },
    }).then((res) => res.json());
  }

  static fetchWalletNFTSAsync({ address, network = "avalanche" }) {
    if (network === "localhost" || network === "fuji" || network === "0x539") {
      return new Promise((resolve) =>
        resolve({
          result: [
            {
              name: "AvaPepes",
              image:
                "https://storageapi.fleek.co/1973d79e-96f0-4cae-b56b-5b4c96b6284c-bucket/Avapepe/images/6340.png",
              attributes: [],
              token_address:
                network === "localhost"
                  ? "0x5FC8d32690cc91D4c39d9d3abcBD16989F875707"
                  : "0x37d0eD258f37a966f33b75b5AE7486917a0ae614",
              token_id: "6340",
              amount: "1",
              contract_type: "ERC721",
              symbol: "AVAPEPE",
              token_uri:
                "https://storageapi.fleek.co/1973d79e-96f0-4cae-b56b-5b4c96b6284c-bucket/Avapepe/metadata/6340.json",
              metadata:
                '{"name":"Avapepe #6340","image":"https://storageapi.fleek.co/1973d79e-96f0-4cae-b56b-5b4c96b6284c-bucket/Avapepe/images/6340.png","attributes":[{"trait_type":"Class","value":"Uncommon"},{"trait_type":"Background","value":"Avalanche"},{"trait_type":"Skin","value":"Light Blue"},{"trait_type":"Face","value":"Mlg Glasses"},{"trait_type":"Mouth","value":"Bubblegum"},{"trait_type":"Outfit","value":"Detective"},{"trait_type":"Hat","value":"Mullet"}]}',
              synced_at: "2021-12-03T00:42:42.007Z",
              is_valid: 1,
              syncing: 0,
              frozen: 0,
            },
          ],
        })
      );
    }

    return this.fetch({
      endpoint: `${address}/nft?chain=${network}&format=decimal`,
    })
      .then((res) => res.result)
      .then((res) =>
        res.map((res) => ({
          ...res,
          network,
        }))
      );
  }
}

export default MoralisClient;
