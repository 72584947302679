import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
  LinearProgress,
  DialogTitle,
  ListItem,
  ListItemAvatar,
  Avatar,
  Dialog,
  List,
  ListItemText,
  IconButton,
  Collapse,
} from "@mui/material";
import { createStructuredSelector } from "reselect";
import { makeSelectWeb3 } from "../Web3Wrapper/selectors";
import {
  makeSelectConnectedWallet,
  makeSelectWrongNetwork,
} from "../ConnectedWalletWrapper/selectors";
import { connect } from "react-redux";
import { connectWeb3 } from "../Web3Wrapper/actions";
import { ArrowDownward } from "@mui/icons-material";
import { Map } from "immutable";
import {
  makeSelectERC20Contract,
  makeSelectThreadDaoContract,
} from "../ContractsWrapper/selectors";
import CoinGeckoClient from "../../clients/CoinGeckoClient";
import { SRLWrapper } from "simple-react-lightbox";
import { useHistory } from "react-router-dom";
import { UnitedStates } from "../../utils/constants";
import {
  createOrder,
  setPaymentProcessor,
} from "../../screens/ProductSwapScreen/actions";
import ERC20ContractFactory from "../../contracts/localhost/MockToken.json";
import { makeSelectPaymentProcessor } from "../../screens/ProductSwapScreen/selectors";
import { makeSelectPaymentProcessors } from "../PaymentProcessorsWrapper/selectors";
import { makeSelectEthBalance } from "../App/selectors";

const tokenImageMap = {
  joetoken: "JoeToken.png",
  dai: "dai.svg",
  "link token": "link token.svg",
  "magic internet money": "magic internet money.svg",
  spell: "spell.png",
};

const ProductCard = ({
  connectedWallet,
  dispatch,
  threadDaoInstance,
  product,
  web3,
  paymentProcessor,
  paymentProcessors,
  wrongNetwork,
  balance,
}) => {
  const [price, setPrice] = useState(0);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [working, setWorking] = useState(false);
  const [showDox, setShowDox] = useState(false);
  const [isOnChain, setIsOnChain] = useState(false);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [changeProcessor, setChangeProcessor] = useState(false);
  const [workingStatus, setWorkingStatus] = useState(false);
  const [NFTContract, setNFTContract] = useState(null);
  const [address, setAddress] = useState(
    Map({
      name: "",
      email: "",
      address1: "",
      city: "",
      state_code: "CA",
      zip: "",
      country_code: "US",
      country_name: "United States",
    })
  );

  useEffect(() => {
    if (connectedWallet) setAddress(address.set("name", connectedWallet));
  }, [connectedWallet]);

  useEffect(() => {
    if (paymentProcessor) {
      fetchTokenBalance();
    }
    fetchPriceAsync();
  }, [paymentProcessor]);

  useEffect(() => {
    if (!product) return;
    setSelectedVariant(product.sync_variants[0]);
  }, [product]);

  useEffect(() => {
    if (!product || !threadDaoInstance) return;
    (async () => {
      const pid = await threadDaoInstance.methods
        .getProductIdBySku(product.id)
        .call();
      if (pid <= 0) {
        setIsOnChain(false);
        return;
      }
      setIsOnChain(true);
      const prod = await threadDaoInstance.methods.getProductById(pid).call();

      console.log(prod);

      if (prod.nftContract === "0x0000000000000000000000000000000000000000")
        return;
      setNFTContract({
        contract: prod.nftContract,
        tokenId: prod.nftTokenId,
        owner: prod.owner,
      });
    })();
  }, [threadDaoInstance]);

  useEffect(() => {
    const interval = setInterval(fetchPriceAsync, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [product]);

  useEffect(() => {
    if (selectedVariant) fetchPriceAsync();
  }, [selectedVariant, threadDaoInstance]);

  const fetchTokenBalance = async () => {
    try {
      const bal = await paymentProcessor.contractInstance.methods
        .balanceOf(connectedWallet)
        .call();

      setTokenBalance(bal / 10 ** paymentProcessor.decimals);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPriceAsync = async () => {
    if (!selectedVariant) return;

    try {
      if (threadDaoInstance) {
        let res;
        if (paymentProcessor) {
          res = await threadDaoInstance.methods
            .getLatestTokenProcessorPriceInUSD({
              contractAddress: paymentProcessor.contractAddress,
              priceFeed: paymentProcessor.priceFeed,
              title: paymentProcessor.title,
              processorId: parseInt(paymentProcessor.processorId, 10),
              decimals: paymentProcessor.decimals,
            })
            .call();
        } else {
          res = await threadDaoInstance.methods
            .getLatestNativePriceInUSD()
            .call();
          console.log("YOOOO", res);
        }

        setPrice(parseFloat(selectedVariant.retail_price) / (res / 10 ** 8));
      } else {
        const _price = await CoinGeckoClient.getAvalancheToUsdAsync();

        setPrice(
          parseFloat(selectedVariant.retail_price) / _price["avalanche-2"].usd
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const purchaseItemAsync = async (e) => {
    e.preventDefault();

    if (!showDox) {
      setShowDox(true);
      return;
    }

    const payload = {
      recipient: {
        ...address.toObject(),
      },
      items: [
        {
          sync_variant_id: selectedVariant.id,
          quantity: 1,
          product: {
            product_id: product.id,
          },
        },
      ],
    };

    const onStatusUpdate = (status) => {
      setWorkingStatus(status);
    };

    const onStateUpdate = (status) => {
      setWorking(status);
    };

    dispatch(
      createOrder({
        payload,
        selectedVariant,
        product,
        onStatusUpdate,
        onStateUpdate,
      })
    );
  };

  const history = useHistory();

  if (!product || !selectedVariant) return null;

  const paymentUrl = paymentProcessor
    ? require(`../../Assets/tokens/${
        tokenImageMap[paymentProcessor.title.toLowerCase()]
      }`)
    : require("../../images/avax.jpeg");

  console.log(NFTContract);

  const PaymentDialog = () => (
    <Dialog onClose={() => setChangeProcessor(false)} open={changeProcessor}>
      <DialogTitle>Change Payment Token</DialogTitle>
      <List sx={{ pt: 0 }}>
        <ListItem
          button
          onClick={() => {
            dispatch(setPaymentProcessor(null));
            setChangeProcessor(false);
          }}
        >
          <ListItemAvatar>
            <Avatar src={require("../../images/avax.jpeg")} />
          </ListItemAvatar>
          <ListItemText primary="AVAX" />
        </ListItem>
        {(paymentProcessors || []).map((processor) => (
          <ListItem
            button
            onClick={() => {
              processor.contractInstance = new web3.eth.Contract(
                ERC20ContractFactory.abi,
                processor.contractAddress
              );
              dispatch(setPaymentProcessor(processor));
              setChangeProcessor(false);
            }}
            key={processor.title}
          >
            <ListItemAvatar>
              <Avatar
                src={require(`../../Assets/tokens/${
                  tokenImageMap[processor.title.toLowerCase()]
                }`)}
              />
            </ListItemAvatar>
            <ListItemText primary={processor.title} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );

  return (
    <Card
      elevation={0}
      className="product-card"
      style={{ display: "flex", flexDirection: "column", margin: "0 auto" }}
    >
      <PaymentDialog />
      <div className="asset-row">
        <Grid container>
          <Grid className="top-row" item xs={12} sm={6}>
            <span className="image-wrapper">
              <div style={{ width: 64 }}>
                <img style={{ width: 64 }} alt="AVAX" src={paymentUrl} />
              </div>
            </span>
            <div className="details">
              <Typography variant="caption" style={{ color: "#6f6f6f" }}>
                Swap from
              </Typography>
              <div style={{ width: "100%", display: "flex" }}>
                <Typography
                  style={{ color: "white", whiteSpace: "nowrap" }}
                  variant="h5"
                >
                  {paymentProcessor ? paymentProcessor.title : "AVAX"}{" "}
                </Typography>
                <IconButton onClick={() => setChangeProcessor(true)}>
                  <ArrowDownward style={{ color: "white" }} />
                </IconButton>
              </div>
            </div>
          </Grid>
          <Grid style={{ justifyContent: "flex-end" }} item xs={12} sm={6}>
            <FormControl style={{ textAlign: "right" }}>
              <TextField
                inputProps={{ readOnly: true }}
                readOnly="readOnly"
                aria-readonly="true"
                className="input"
                value={price}
                variant="outlined"
              />
              <FormHelperText
                id="filled-weight-helper-text"
                style={{
                  color: "#6f6f6f",
                  position: "absolute",
                  bottom: -20,
                  right: 0,
                }}
              >
                {!paymentProcessor
                  ? `bal ${balance} - `
                  : `bal ${tokenBalance} - `}
                ${selectedVariant.retail_price} usd
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <div style={{ height: 40, paddingLeft: 20, position: "relative" }}>
        <div className="arrow-wrap">
          <ArrowDownward style={{ color: "white" }} />
        </div>
      </div>
      <div className="asset-row">
        <Grid container>
          <Grid className="top-row" item xs={12} sm={6}>
            <span style={{ cursor: "pointer" }} className="image-wrapper">
              <SRLWrapper>
                <a href={product.thumbnail_url}>
                  <img alt={product.title} src={`${product.thumbnail_url}`} />
                </a>
              </SRLWrapper>
            </span>
            <div className="details">
              <Typography
                style={{ color: "white", whiteSpace: "normal" }}
                variant="h5"
              >
                {product.name}
              </Typography>
            </div>
          </Grid>

          <Grid style={{ justifyContent: "flex-end" }} item xs={12} sm={6}>
            <Select
              style={{
                backgroundColor: "#161522",
                borderRadius: 10,
                marginLeft: 25,
              }}
              className="input"
              onChange={(e) => {
                setSelectedVariant(
                  product.sync_variants.filter(
                    (v) => v.name === e.target.value
                  )[0]
                );
              }}
              value={selectedVariant.name}
              variant="outlined"
            >
              {product.sync_variants.map((variant) => (
                <MenuItem value={variant.name} key={variant.id}>
                  <Typography>{variant.name.split(" - ").pop()}</Typography>
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </div>
      {Boolean(NFTContract) && (
        <div style={{ textAlign: "left", paddingTop: 12 }}>
          <Typography color="primary">About this NFT</Typography>
          <a
            href={`https://snowtrace.io/address/${NFTContract.owner}`}
            target="_blank"
          >
            <Typography color="primary" variant="caption">
              Owner: {NFTContract.owner}
            </Typography>
          </a>
          <br />
          <a
            href={`https://snowtrace.io/address/${NFTContract.contract}`}
            target="_blank"
          >
            <Typography color="primary" variant="caption">
              Contract: {NFTContract.contract}
            </Typography>
          </a>
          <br />
          <Typography color="primary" variant="caption">
            Token Id: {NFTContract.tokenId}
          </Typography>
        </div>
      )}
      <form
        onSubmit={purchaseItemAsync}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {showDox && (
          <div className="dox-box" style={{ textAlign: "left" }}>
            <Typography style={{ paddingTop: 18, color: "white" }} paragraph>
              Dox (Shipping)
            </Typography>
            <Grid className="deets" spacing={2} container>
              <Grid item xs={12} sm={6}>
                <TextField
                  color="primary"
                  required
                  type="email"
                  value={address.get("email")}
                  onChange={(e) =>
                    setAddress(address.set("email", e.target.value))
                  }
                  fullWidth
                  variant="outlined"
                  label="Email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  value={address.get("address1")}
                  onChange={(e) =>
                    setAddress(address.set("address1", e.target.value))
                  }
                  fullWidth
                  variant="outlined"
                  label="Street Address"
                />
              </Grid>
            </Grid>
            <Grid
              className="deets"
              spacing={2}
              container
              style={{ paddingTop: 18 }}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  value={address.get("city")}
                  onChange={(e) =>
                    setAddress(address.set("city", e.target.value))
                  }
                  fullWidth
                  variant="outlined"
                  label="City"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  value={address.get("state_code")}
                  onChange={(e) =>
                    setAddress(address.set("state_code", e.target.value))
                  }
                  fullWidth
                  variant="outlined"
                  label="State"
                >
                  {UnitedStates.map((state) => (
                    <MenuItem key={state.value} value={state.value}>
                      <Typography color="primary">{state.key}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid
              style={{ paddingTop: 18 }}
              className="deets"
              container
              spacing={2}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  onClick={() =>
                    alert("Threadswap currently only ships to the US.")
                  }
                  inputProps={{ readOnly: true }}
                  required
                  value={address.get("country_code")}
                  onChange={(e) =>
                    setAddress(address.set("country_code", e.target.value))
                  }
                  fullWidth
                  variant="outlined"
                  label="Country"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  value={address.get("zip")}
                  onChange={(e) =>
                    setAddress(address.set("zip", e.target.value))
                  }
                  fullWidth
                  variant="outlined"
                  label="Zip"
                />
              </Grid>
            </Grid>
          </div>
        )}
        <div style={{ height: 40 }} />
        <div className="buy-button-wrapper">
          {!connectedWallet && (
            <Button
              onClick={async () => {
                dispatch(connectWeb3());
              }}
              variant="contained"
              fullWidth
            >
              CONNECT WALLET
            </Button>
          )}
          {connectedWallet && !wrongNetwork && !isOnChain && (
            <Button color="secondary" disabled variant="contained" fullWidth>
              <Typography style={{ color: "white" }}>
                PRODUCT NOT ON CHAIN
              </Typography>
            </Button>
          )}
          {connectedWallet && wrongNetwork && (
            <Button
              onClick={async () => {
                await window.ethereum.request({
                  method: "wallet_switchEthereumChain",
                  params: [{ chainId: "0xA86A" }],
                });
              }}
              color="secondary"
              variant="contained"
              fullWidth
            >
              <Typography style={{ color: "white" }}>WRONG NETWORK</Typography>
            </Button>
          )}
          {Boolean(connectedWallet) && isOnChain && !wrongNetwork && (
            <Button
              type="submit"
              disabled={Boolean(working)}
              size="large"
              variant="contained"
              fullWidth
            >
              {working ? (
                <div>
                  <Typography color="primary">
                    {workingStatus || "WORKING"}
                  </Typography>
                  <LinearProgress
                    color="primary"
                    variant="indeterminate"
                    size="small"
                  />
                </div>
              ) : (
                "SWAP FOR ITEM"
              )}
            </Button>
          )}
        </div>
      </form>
    </Card>
  );
};

const mapStateToProps = createStructuredSelector({
  connectedWallet: makeSelectConnectedWallet(),

  web3: makeSelectWeb3(),

  threadDaoInstance: makeSelectThreadDaoContract(),

  ERC20Contract: makeSelectERC20Contract(),

  paymentProcessor: makeSelectPaymentProcessor(),

  paymentProcessors: makeSelectPaymentProcessors(),

  wrongNetwork: makeSelectWrongNetwork(),

  balance: makeSelectEthBalance(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
// https://us-central1-the-platform-7f1e8.cloudfunctions.net/imageTransform/cdn/image/width=300/assets%2Ft8958black-01.jpg?GoogleAccessId=server-access%40the-platform-7f1e8.iam.gserviceaccount.com&Expires=16470633600&Signature=ZZC7%2FusQOKV%2FOq6OOl%2FwNN3vyOGz7V0O3tdGgCGPrc6B7IlS3njc8dqC55UpBjWO23xNh7NYf%2BhaUfZMmFSDn5cNqY%2F6JWwI5rKDOq6g3w8%2F7bDgbYi56Hlkf0flHuETAFwitr3B%2BqS5zxdm6MksNoHRcE6YWJefdirUQldBAgN%2BE9K1cyTWwdnS00e0cWFdPezdJypBiw8oandVdx3jsYG67cHhDMmeTyH044V8teBSdLyMUUDezq3A%2F3mR96zMKHSQLscoMmkQksnP94KdEQdkqath1LoYru0sEwr6pFCFD6w1W1BGYTl26dlrAiwC2FcSmDqQ9YompOIJFHZ7DA%3D%3D
// https://us-central1-the-platform-7f1e8.cloudfunctions.net/imageTransform/cdn/image/width=300/assets%2Fhttps://amcloud-storage.s3.amazonaws.com/OfflineInc/img/17807.jpg?GoogleAccessId=server-access%40the-platform-7f1e8.iam.gserviceaccount.com&Expires=16470604800&Signature=ANJexQJOz0UAg4TlZQohK7oTIHDX3DLA794ZV01AhSnOY0Eod0B349c8d68LfTurfzNGg17eg0JoWS51QfVTwVRcsIjdZ5FYmhLy5okn6eqxEmbM3qGs7i%2BjMlcbnx0cMUScqTzIHRID89u3WQRs%2FtWqwqaMxk%2FeC2R%2BClOOrLYX9ejq%2FGcxmUZXFNdO2oBG0hMuhsSUxx47Vfe82I9Y46Zoe%2B%2FvwiJxbgiDv87vkArrRqX3WRzvQThgIxFUA%2FFr35WxmRhl0SmIyo3RdvnbjfcA7yuL0Qm8SM3ucIDtTyuTMqWFzioZQtrLgZvg7dzfBab1fkMtBautrFoHsRuUpw%3D%3D
