import React from "react";
import App from "./containers/App";
import { Provider } from "react-redux";
import store from "./store";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";
import SimpleReactLightbox from "simple-react-lightbox";

export default () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SimpleReactLightbox>
          <App />
        </SimpleReactLightbox>
      </ThemeProvider>
    </Provider>
  );
};
