import { fromJS } from "immutable";
import { SET_CHAIN, SET_PROVIDER, SET_WEB3 } from "./constants";

const initialState = fromJS({
  web3: null,
  provider: null,
  chainId: null,
});

function web3Reducer(state = initialState, action) {
  switch (action.type) {
    case SET_WEB3:
      return {
        ...state,
        web3: action.web3,
      };

    case SET_PROVIDER:
      return {
        ...state,
        provider: action.provider,
      };

    case SET_CHAIN:
      return {
        ...state,
        chainId: action.chainId,
      };

    default:
      return state;
  }
}

export { web3Reducer };
