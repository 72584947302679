import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import reducer from "./reducers";
import web3RootSaga from "./containers/Web3Wrapper/sagas";
import rootConnectedWalletSaga from "./containers/ConnectedWalletWrapper/sagas";
import rootContractsSaga from "./containers/ContractsWrapper/sagas";
import rootProductPageSaga from "./screens/ProductSwapScreen/sagas";
import rootAppSaga from "./containers/App/sagas";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(web3RootSaga);
sagaMiddleware.run(rootConnectedWalletSaga);
sagaMiddleware.run(rootContractsSaga);
sagaMiddleware.run(rootProductPageSaga);
sagaMiddleware.run(rootAppSaga);

export default store;
