import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import SelectPrintfulProduct from "../SelectPrintfulProduct";
import { CloseOutlined } from "@mui/icons-material";
import Slide from "@mui/material/Slide";

const SelectProductDialog = ({ onSelect, onClose }) => {
  return (
    <Dialog open fullScreen>
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "#17101f",
          color: "white",
        }}
      >
        <IconButton disabled style={{ opacity: 0 }}>
          <CloseOutlined />
        </IconButton>
        <Typography>
          Select the item you like to add your NFT artwork to
        </Typography>
        <IconButton onClick={onClose}>
          <CloseOutlined style={{ color: "white" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ backgroundColor: "#17101f", color: "white" }}>
        <SelectPrintfulProduct onItemSelected={onSelect} />
      </DialogContent>
    </Dialog>
  );
};

export default SelectProductDialog;
