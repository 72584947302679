import React, { useEffect, useState } from "react";
import {
  ImageListItemBar,
  Typography,
  CircularProgress,
  Grid,
} from "@mui/material";
import PrintfulClient from "../../clients/PrintfulClient";
import PrintfulCatalogProduct from "../../models/PrintfulCatalogProduct";

const CatalogProductSelectorRow = ({ type, filter, onItemSelected }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { result } = await PrintfulClient.fetchPrintfulProductsByType({
        type,
      });

      setItems(
        result
          .filter((i) => !i.is_discontinued)
          .filter(filter)
          .map((res) => new PrintfulCatalogProduct(res))
      );
      setLoading(false);
    })();
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      {loading && <CircularProgress />}
      <Grid container spacing={1}>
        {items.map((item) => (
          <Grid item xs={3} key={item.image}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                onItemSelected(item);
              }}
            >
              <img
                style={{ borderRadius: 10 }}
                src={`${item.image}`}
                srcSet={`${item.image}`}
                alt={item.title}
                loading="lazy"
              />
            </div>
            <ImageListItemBar
              title={<Typography variant="caption">{item.title}</Typography>}
              subtitle={<span>{item.brand}</span>}
              position="below"
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default CatalogProductSelectorRow;
