import { createStructuredSelector } from "reselect";
import { makeSelectThreadDaoContract } from "../ContractsWrapper/selectors";
import {
  makeSelectConnectedWallet,
  makeSelectWrongNetwork,
} from "../ConnectedWalletWrapper/selectors";
import { makeSelectWeb3 } from "../Web3Wrapper/selectors";
import { connect } from "react-redux";
import { Button, TextField, Typography, Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { connectWeb3 } from "../Web3Wrapper/actions";

const ListProductTab = ({
  nft,
  printfulProduct,
  wrongNetwork,
  onCreate,
  dispatch,
  web3,
  connectedWallet,
}) => {
  const [creatingProduct, setCreatingProduct] = useState(false);
  const [retailPrice, setRetailPrice] = useState(0);
  const [title, setTitle] = useState(nft.name);
  const [cost, setCost] = useState(0);

  const nftOnAvalanche = useRef(nft.network === "0xa86a").current;

  useEffect(() => {
    setRetailPrice(
      Math.round(parseFloat(printfulProduct.variants[0].price) + 10)
    );
    setCost(parseFloat(printfulProduct.variants[0].price));
  }, [printfulProduct]);

  const createProduct = async (e) => {
    try {
      e.preventDefault();

      if (!web3) {
        dispatch(connectWeb3());
        return;
      }

      onCreate({ retailPrice, cost, title });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div style={{ textAlign: "left", paddingTop: 12 }}>
      {nftOnAvalanche && (
        <Typography paragraph variant="caption">
          Add your item to the ThreadSwap marketplace and receive a 90/10 split
          of the profits. These are distribute to the CURRENT owner of the NFT
          used in this product. Profits are distribute as ThreadTokens which are
          redeemable for the cash equivalent of AVAX.
        </Typography>
      )}
      {!nftOnAvalanche && (
        <Typography paragraph variant="caption">
          Unfortunately only NFTs on the Avalanche network are able to
          participate in the Threadswap marketplace. But the good news is you
          can still create your product and purchase it! Simply change your
          network to Avalanche and publish the product where you can then order
          your item.
        </Typography>
      )}
      <form
        style={{ flexDirection: "column", display: "flex" }}
        onSubmit={createProduct}
      >
        <div>
          <TextField
            required
            fullWidth
            label="Product Name"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            variant="outlined"
            style={{ marginBottom: 18 }}
          />

          {nftOnAvalanche && (
            <div>
              <TextField
                fullWidth
                required
                type="number"
                label="Product Price"
                onChange={(e) => setRetailPrice(parseFloat(e.target.value))}
                value={retailPrice.toFixed(2)}
                style={{ marginBottom: 18 }}
              />

              <Typography color="secondary">
                -Product Cost: ${cost.toFixed(2)}
              </Typography>

              <Typography color="secondary">
                -Protocol Fee: ${((retailPrice - cost) * 0.1).toFixed(2)}
              </Typography>
              <Typography>
                You make:{" "}
                <span style={{ fontSize: 24, color: "#62bfb1" }}>
                  ${((retailPrice - cost) * 0.9).toFixed(2)}
                </span>{" "}
                minus shipping & tax
              </Typography>
            </div>
          )}
        </div>
        <br />
        {!wrongNetwork && (
          <Button
            size="large"
            disabled={creatingProduct}
            variant="contained"
            onClick={createProduct}
          >
            {creatingProduct
              ? "BUIDLING"
              : connectedWallet
              ? "CREATE PRODUCT"
              : "CONNECT WALLET"}
          </Button>
        )}
        {wrongNetwork && (
          <Button
            size="large"
            disabled={creatingProduct}
            variant="contained"
            onClick={async () => {
              await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0xa86a" }], // chainId must be in hexadecimal numbers
              });
            }}
          >
            SWITCH TO AVALANCHE
          </Button>
        )}
      </form>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  threadDaoInstance: makeSelectThreadDaoContract(),

  connectedWallet: makeSelectConnectedWallet(),

  web3: makeSelectWeb3(),

  wrongNetwork: makeSelectWrongNetwork(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ListProductTab);
