import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import { createStructuredSelector } from "reselect";
import { makeSelectConnectedWallet } from "../../containers/ConnectedWalletWrapper/selectors";
import { makeSelectThreadDaoContract } from "../../containers/ContractsWrapper/selectors";
import { connect } from "react-redux";
import { makeSelectWeb3 } from "../../containers/Web3Wrapper/selectors";
import { makeSelectPaymentProcessors } from "../../containers/PaymentProcessorsWrapper/selectors";
import ERC20ContractFactory from "../../contracts/localhost/MockToken.json";

const PayVendorScreen = ({
  web3,
  threadDaoInstance,
  connectedWallet,
  paymentProcessors,
}) => {
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    (async () => {
      if (!web3 || !threadDaoInstance) return;
      console.log(threadDaoInstance);
      const _amount = await web3.eth.getBalance(threadDaoInstance._address);
      setAmount(_amount);
    })();
  }, [web3, threadDaoInstance]);

  const handlePayVendor = async () => {
    await threadDaoInstance.methods.payVendor(amount).send(
      {
        from: connectedWallet,
      },
      function (err, res) {
        if (!err) {
          alert("Success");
        } else {
          console.log(err);
        }
      }
    );
  };

  return (
    <Container>
      <Table>
        <TableBody style={{ color: "white" }}>
          <TableRow>
            <TableCell>
              <img
                style={{ width: 60 }}
                src={require("../../images/avax.jpeg")}
              />
            </TableCell>
            <TableCell>
              <Typography style={{ color: "white" }}>{amount}</Typography>
            </TableCell>
            <TableCell>
              <Button variant="outlined" onClick={handlePayVendor}>
                TRANSFER
              </Button>
            </TableCell>
          </TableRow>
          {(paymentProcessors || []).map((processor) => (
            <ProcessorRow
              key={processor.processorId}
              processor={processor}
              web3={web3}
              threadDaoInstance={threadDaoInstance}
              connectedWallet={connectedWallet}
            />
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

const ProcessorRow = ({
  processor,
  web3,
  threadDaoInstance,
  connectedWallet,
}) => {
  const [balance, setBalance] = useState(0);
  useEffect(() => {
    (async () => {
      processor.contractInstance = new web3.eth.Contract(
        ERC20ContractFactory.abi,
        processor.contractAddress
      );
      const bal = await processor.contractInstance.methods
        .balanceOf(threadDaoInstance._address)
        .call();

      setBalance(bal);
    })();
  }, [processor]);

  const handlePayVendor = async () => {
    await threadDaoInstance.methods
      .payVendorTokens(processor.processorId, balance)
      .send({ from: connectedWallet }, function (err, res) {
        if (!err) {
          alert("Success");
        } else {
          console.log(err);
        }
      });
    console.log("POSTY");
  };

  return (
    <TableRow>
      <TableCell>
        <Typography style={{ color: "white" }}>{processor.title}</Typography>
      </TableCell>
      <TableCell>
        <Typography style={{ color: "white" }}>
          {balance / 10 ** processor.decimals}
        </Typography>
      </TableCell>
      <TableCell>
        <Button onClick={handlePayVendor} variant="outlined">
          TRANSFER
        </Button>
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = createStructuredSelector({
  web3: makeSelectWeb3(),

  threadDaoInstance: makeSelectThreadDaoContract(),

  connectedWallet: makeSelectConnectedWallet(),

  paymentProcessors: makeSelectPaymentProcessors(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PayVendorScreen);
