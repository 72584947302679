import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { createStructuredSelector } from "reselect";
import { makeSelectConnectedWallet } from "../../containers/ConnectedWalletWrapper/selectors";
import { makeSelectWeb3 } from "../../containers/Web3Wrapper/selectors";
import { makeSelectThreadDaoContract } from "../../containers/ContractsWrapper/selectors";
import { connect } from "react-redux";
import PrintfulClient from "../../clients/PrintfulClient";

const PublishProductsScreen = ({ threadDaoInstance, connectedWallet }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    loadProductsAsync();
  }, []);

  const loadProductsAsync = async () => {
    const p = await threadDaoInstance.methods
      .productsByAddress(connectedWallet)
      .call();

    console.log("MINE", p);

    try {
      const _products = await PrintfulClient.fetchProducts();

      setProducts(_products.result);
    } catch (err) {
      console.log(err);
    }
  };

  const publishProductAsync = async (_product) => {
    const { result } = await PrintfulClient.fetchProductById(_product.id);

    const price = parseFloat(result.sync_variants[0].retail_price) * 100;

    threadDaoInstance.methods
      .mintProduct(
        result.sync_product.name,
        price,
        1500,
        result.sync_product.id,
        result.sync_product.thumbnail_url
      )
      .send(
        {
          from: connectedWallet,
        },
        function (err, res) {
          if (!err) {
            alert("Success");
          } else {
            console.log(err);
          }
        }
      )
      .on("confirmation", (a, receipt) => {})
      .on("error", console.error);
  };

  console.log(products);

  return (
    <Container style={{ paddingTop: 90 }}>
      <Grid container spacing={2}>
        {products.map((product) => (
          <Grid key={product.id} item xs={3}>
            <Card>
              <CardMedia>
                <img alt={product.name} src={product.thumbnail_url} />
              </CardMedia>
              <CardContent>
                <Typography>{product.name}</Typography>
              </CardContent>
              <CardActions>
                <Button
                  onClick={() => {
                    publishProductAsync(product);
                  }}
                  fullWidth
                  variant="contained"
                >
                  <Typography>PUBLISH</Typography>
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  connectedWallet: makeSelectConnectedWallet(),

  web3: makeSelectWeb3(),

  threadDaoInstance: makeSelectThreadDaoContract(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublishProductsScreen);
