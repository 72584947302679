import React from "react";
import { Container, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Container style={{ position: "relative", zIndex: 2 }}>
      <div>
        <br />
        <Typography paragraph>THREADSWAP.XYZ</Typography>
        <Typography>
          Created with 🧠 by{" "}
          <a
            style={{ textDecoration: "underline" }}
            href="https://geoffabbott.com"
            target="_blank"
          >
            Geoff Abbott
          </a>
        </Typography>
        <br />
        <br />
      </div>
    </Container>
  );
};

export default Footer;
