import React from "react";
import { Button, Container, Grid, Hidden, Typography } from "@mui/material";
import StoreGrid from "../../containers/StoreGrid";
import { Link } from "react-router-dom";
import Snow from "../../components/Snow";
import HowItWorksRow from "../../containers/HowItWorksRow";
import Helmet from "react-helmet";

const HomeScreen = () => {
  return (
    <Container>
      <Helmet>
        <title>THREADSWAP.XYZ</title>
      </Helmet>
      <div id="hp-banner" style={{ position: "relative" }}>
        <Snow />
        <div style={{ marginTop: -135, position: "relative", zIndex: 999999 }}>
          <Typography
            sx={{
              fontSize: { xs: "2.4rem", sm: "3.75rem" },
              textShadow: {
                xs: "rgb(117 117 117) 2px 2px",
                sm: "2px 4px #757575",
              },
            }}
            variant="h2"
            color="primary"
          >
            THREADSWAP.XYZ
          </Typography>
          <Typography
            variant="h5"
            sx={{ fontSize: { xs: "0.95rem", sm: "1.5rem" } }}
            style={{ color: "#757575" }}
          >
            CREATE A MERCH LINE FROM YOUR NFTS
          </Typography>
          <Link to={`/create-product/select-nft`}>
            <Button size="large" className="hp-b-cta" variant="contained">
              <Typography variant="h5">GET STARTED</Typography>
            </Button>
          </Link>
        </div>
      </div>
      <br />
      <br />
      <HowItWorksRow />
      <br />
      <br />
      <br />
      <Typography color="primary" variant="h3" className="textShadow">
        POPULAR ITEMS
      </Typography>
      <div style={{ height: 30 }} />
      <StoreGrid />
      <Hidden only="xs">
        <br />
        <br />
      </Hidden>
      <div style={{ width: "100%", maxWidth: 500, margin: "0 auto" }}>
        <Link to="/shop">
          <Button
            style={{ boxShadow: "#757575 5px 4px" }}
            fullWidth
            variant="contained"
            size="large"
          >
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              GO SHOPPING
            </Typography>
          </Button>
        </Link>
      </div>
      <br />
      <br />
    </Container>
  );
};

export default HomeScreen;
