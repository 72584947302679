import { put, takeLatest, all } from "redux-saga/effects";
import { setConnectedWallet } from "./actions";
import { SET_WEB3 } from "../Web3Wrapper/constants";
import { fetchEthBalance, fetchThreadBalance } from "../App/actions";

export function* connectWalletSaga({ web3 }) {
  try {
    if (!web3) return;

    const accounts = yield web3.eth.getAccounts();

    yield put(setConnectedWallet(accounts[0]));

    yield put(fetchEthBalance());

    yield put(fetchThreadBalance());
  } catch (err) {
    console.log(err);
  }
}

export function* connectWalletWatcher() {
  yield takeLatest(SET_WEB3, connectWalletSaga);
}

export default function* rootConnectedWalletSaga() {
  yield all([connectWalletWatcher()]);
}
