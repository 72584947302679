import ObjectID from "bson-objectid";
import PrintfulCatalogProduct from "../../models/PrintfulCatalogProduct";

const host = "https://us-central1-thread-dao.cloudfunctions.net/app";
// const host = "http://localhost:5001/thread-dao/us-central1/app";

class PrintfulClient {
  static fetch(payload, endpoint) {
    return fetch(`${host}/printful${endpoint ? endpoint : ""}`, {
      method: "POST",
      headers: {
        accept: "application/json",
      },
      body: JSON.stringify(payload),
    }).then(async (res) => {
      if (res.statusCode >= 400) {
        const err = await res.json();

        throw new Error(err.message);
      }

      return res.json();
    });
  }

  static createDraft(payload) {
    return this.fetch({ payload }, "/orders");
  }

  static approveDraft(payload) {
    return this.fetch({ payload }, "/orders/approve");
  }

  static fetchPrintfulProducts() {
    return this.fetch({ endpoint: "products", method: "GET" });
  }

  static fetchPrintfulProductById(id = 5) {
    return this.fetch({ endpoint: `products/${id}`, method: "GET" }).then(
      ({ result }) => new PrintfulCatalogProduct(result)
    );
  }

  static fetchPrintfulProductsByType({ type }) {
    return this.fetch({ endpoint: `products?type=${type}`, method: "GET" });
  }

  static fetchProducts({ limit = 9 } = {}) {
    return this.fetch({
      endpoint: `store/products?limit=${limit}`,
      method: "GET",
    });
  }

  static fetchProductById(id) {
    return this.fetch({ endpoint: `store/products/${id}`, method: "GET" });
  }

  static fetchPrintFilesById(id) {
    return this.fetch({
      endpoint: `mockup-generator/printfiles/${id}`,
      method: "GET",
    });
  }

  static fetchTask(taskKey = "x1981f17dd68c72b00860d95af89f23a") {
    return this.fetch({
      endpoint: `mockup-generator/task?task_key=${taskKey}`,
      method: "GET",
    });
  }

  static async createProduct({
    thumbnail,
    img,
    retail_price = 25,
    name,
    variants,
    catalogProduct,
    printFile,
    o_left,
    o_top,
    o_width,
    o_height,
  }) {
    const params = await catalogProduct.getSampleParams({
      variant_ids: variants,
      img,
      printFile,
      o_left,
      o_top,
      o_width,
      o_height,
    });

    return this.fetch(
      {
        endpoint: `store/products`,
        method: "POST",
        payload: {
          sync_product: {
            external_id: ObjectID(),
            name,
            thumbnail,
          },
          sync_variants: variants.map((variant_id) => ({
            variant_id,
            option_groups: params.option_groups,
            format: "png",
            retail_price,
            files: params.files,
          })),
        },
      },
      "-create-product"
    );
  }

  static fetchMockUp(payload, productId) {
    return this.fetch({
      endpoint: `mockup-generator/create-task/${productId}`,
      payload,
    });
  }

  static fetchMockUpAsync(payload, productId, onUpdate) {
    console.log(payload);
    return new Promise(async (resolve, reject) => {
      const res = await this.fetch({
        endpoint: `mockup-generator/create-task/${productId}`,
        payload,
      });

      if (res.error) {
        if (res.statusCode === 429) {
          reject(
            new Error(
              "Mock up generating is currently throttled. Please try again in 1 minute"
            )
          );
        } else {
          reject(new Error("Something went wrong"));
        }
        return;
      }

      const interval = setInterval(async () => {
        const { result } = await this.fetchTask(res.result.task_key);
        if (result.status === "completed") {
          clearInterval(interval);
          resolve(result.mockups);
        } else if (typeof onUpdate === "function") {
          onUpdate(result);
        }
        if (result.status === "failed") {
          clearInterval(interval);
          reject(result.error);
        }
      }, 3000);
    });
  }
}

export default PrintfulClient;
