import { put, takeLatest, all, select } from "redux-saga/effects";
import { CREATE_ORDER, LOAD_PRODUCT } from "./constants";
import { setLoadingProduct, setProcessingOrder, setProduct } from "./actions";
import Web3 from "web3";
import Swal from "sweetalert2";
import { makeSelectPaymentProcessor } from "./selectors";
import { makeSelectThreadDaoContract } from "../../containers/ContractsWrapper/selectors";
import { makeSelectConnectedWallet } from "../../containers/ConnectedWalletWrapper/selectors";
import { makeSelectWeb3 } from "../../containers/Web3Wrapper/selectors";
import PrintfulClient from "../../clients/PrintfulClient";

export function* loadProductSaga({ handle }) {
  try {
    yield put(setLoadingProduct(true));

    const { result } = yield PrintfulClient.fetchProductById(handle);

    const { sync_product, sync_variants } = result;

    const pRes = yield PrintfulClient.fetchPrintfulProductById(
      sync_variants[0].product.product_id
    );

    for (const a of pRes.variants) {
      console.log(a);
    }

    yield put(
      setProduct({
        ...sync_product,
        sync_variants,
      })
    );

    setTimeout(() => {
      window.prerenderReady = true;
    }, 1000);

    yield put(setLoadingProduct(false));
  } catch (err) {
    console.log(err);
  }
}

export function* createOrderSaga(action) {
  let res;

  const { selectedVariant, product, payload, onStatusUpdate, onStateUpdate } =
    action.payload;

  const paymentProcessor = yield select(makeSelectPaymentProcessor());
  const threadDaoInstance = yield select(makeSelectThreadDaoContract());
  const connectedWallet = yield select(makeSelectConnectedWallet());
  const web3 = yield select(makeSelectWeb3());

  const productId = yield threadDaoInstance.methods
    .getProductIdBySku(product.id)
    .call();

  try {
    onStateUpdate(true);

    onStatusUpdate("PREPARING ORDER");

    if (paymentProcessor) {
      res = yield threadDaoInstance.methods
        .getLatestTokenProcessorPriceInUSD({
          contractAddress: paymentProcessor.contractAddress,
          priceFeed: paymentProcessor.priceFeed,
          title: paymentProcessor.title,
          processorId: parseInt(paymentProcessor.processorId, 10),
          decimals: paymentProcessor.priceFeed,
        })
        .call();
    } else {
      res = yield threadDaoInstance.methods.getLatestNativePriceInUSD().call();
    }

    const finalPrice =
      (parseFloat(selectedVariant.retail_price) * 100) / (res / 10 ** 6);

    const { result } = yield PrintfulClient.createDraft(payload);

    const draftOrder = result;

    if (!draftOrder) throw new Error("Unable to create draft");

    // const costs = Math.round(
    //   (parseFloat(draftOrder.costs.shipping) +
    //     parseFloat(draftOrder.costs.tax)) *
    //     100
    // );
    const costs = 0;

    const params = {
      value: Web3.utils.toWei(finalPrice.toString()),
      from: connectedWallet,
    };

    const onReceipt = async (tx, err) => {
      try {
        // await PrintfulClient.approveDraft(draftOrder);

        onStateUpdate(false);

        onStateUpdate(false);

        await Swal.fire(
          "SUCCESS",
          "You should receive an order confirmation shortly",
          "success"
        );
      } catch (err) {
        await put(setProcessingOrder(false));
        onStateUpdate(false);
        onStateUpdate(false);
        console.log(err);
        window.alert("ERROR", err.message);
      }
    };

    const variantId = selectedVariant.id;
    const orderId = draftOrder.id;
    const imageUrl = product.thumbnail_url;

    if (paymentProcessor) {
      onStatusUpdate("Approve tokens for payment");

      const approvalAmount = Web3.utils
        .toBN(
          Web3.utils
            .toBN(parseFloat(selectedVariant.retail_price) * 100)
            .mul(Web3.utils.toBN(10).pow(Web3.utils.toBN(24)))
        )
        .div(Web3.utils.toBN(res))
        .toString();

      yield new Promise(async (resolve, reject) => {
        paymentProcessor.contractInstance.methods
          .approve(threadDaoInstance._address, approvalAmount)
          .send({ from: connectedWallet }, async (err, tx) => {
            if (!err) {
              onStatusUpdate("Waiting for approval to be mined");
            }
          })
          .on("receipt", async (tx, err) => {
            if (!err) {
              resolve(tx);
            } else {
              console.log(err);
              reject(err);
            }
          });
      });

      onStatusUpdate("APPROVE ORDER");

      threadDaoInstance.methods
        .mintOrderWithPaymentProcessor(
          productId,
          variantId,
          orderId,
          costs,
          paymentProcessor.processorId,
          imageUrl
        )
        .send({ from: connectedWallet }, (err, tx) => {
          web3.eth.getTransactionReceipt(tx, onReceipt);
          onStatusUpdate("WAITING FOR ORDER TO BE MINED");
        });
    } else {
      onStatusUpdate("Approve Order Transaction");

      yield threadDaoInstance.methods
        .mintNativePaymentOrder(productId, variantId, orderId, costs, imageUrl)
        .send(params, (err, tx) => {
          if (err) {
            alert("User cancelled transaction");
            return;
          }
          web3.eth.getTransactionReceipt(tx, onReceipt);
          onStatusUpdate("WAITING FOR ORDER TO BE MINED");
        });
    }
  } catch (err) {
    onStateUpdate(false);
    console.log(err);
    // window.alert(parseError(err));
  }
}

export function* loadProductWatcher() {
  yield takeLatest(LOAD_PRODUCT, loadProductSaga);
}

export function* createOrderWatcher() {
  yield takeLatest(CREATE_ORDER, createOrderSaga);
}

export default function* rootProductPageSaga() {
  yield all([loadProductWatcher(), createOrderWatcher()]);
}
