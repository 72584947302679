import { fromJS } from "immutable";
import { SET_LIGHT_BOX_IMAGES } from "./constants";

const initialState = fromJS({
  images: [],
});

function lightBoxReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LIGHT_BOX_IMAGES:
      return {
        ...state,
        images: action.images,
      };

    default:
      return state;
  }
}

export { lightBoxReducer };
