import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import React from "react";
import { Button, Typography } from "@mui/material";
import { makeSelectEthBalance } from "../App/selectors";
import { connectWeb3 } from "../Web3Wrapper/actions";
import { makeSelectChainId } from "../Web3Wrapper/selectors";

const ConnectedWalletButton = ({
  connectedWallet,
  balance,
  dispatch,
  chainId,
}) => {
  if (!connectedWallet || !chainId)
    return (
      <Button onClick={() => dispatch(connectWeb3())} variant="outlined">
        <Typography>CONNECT WALLET</Typography>
      </Button>
    );

  return (
    <Button variant="outlined" fullWidth>
      <img
        alt="avax logo"
        style={{ borderRadius: 3, height: 20 }}
        src={require(`../../Assets/networks/${chainId}.svg`)}
      />
      {"  "}
      <Typography>
        {balance} ...{connectedWallet.slice(-4, connectedWallet.length)}
      </Typography>
    </Button>
  );
};

const mapStateToProps = createStructuredSelector({
  balance: makeSelectEthBalance(),
  chainId: makeSelectChainId(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedWalletButton);
