import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

const NFTCard = ({ metadata }) => {
  const [nft, setNFT] = useState(null);

  useEffect(() => {
    loadMetadataAsync();
  }, [metadata]);

  const loadMetadataAsync = async () => {
    try {
      const res = await fetch(
        metadata.token_uri.replace("ipfs.moralis.io:2053", "ipfs.io"),
        {
          headers: { accept: "application/json" },
        }
      ).then((res) => res.json());

      if (res.image.indexOf("ipfs://") >= 0)
        res.image = `https://ipfs.io/ipfs/${res.image.split("ipfs://").pop()}`;

      setNFT({
        ...metadata,
        ...res,
      });
    } catch (err) {
      console.log(err);
      setNFT({});
    }
  };

  if (!nft) return <CircularProgress />;

  console.log(nft);

  return (
    <Card elevation={0} style={{ backgroundColor: "transparent" }}>
      <Link
        to={{
          pathname: "/create-product/select-product",
          state: { nft },
        }}
      >
        <CardMedia
          image={nft.image || require("../../images/no_image.webp")}
          height={271}
          style={{ height: 271, overflow: "hidden", borderRadius: 13 }}
        />
      </Link>
      <CardContent>
        <Typography color="primary">{nft.name || "Not Avail"}</Typography>
      </CardContent>
      <CardActions>
        <Link
          style={{ width: "100%" }}
          to={{
            pathname: "/create-product/select-product",
            state: { nft },
          }}
        >
          <Button disabled={!nft.image} variant="outlined" fullWidth>
            {nft.image ? "SELECT" : "NOT AVAIALABLE"}
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
};

export default NFTCard;
