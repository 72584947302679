import {
  SET_PRODUCT,
  SET_PAYMENT_PROCESSOR,
  LOAD_PRODUCT,
  SET_LOADING_PRODUCT,
  CREATE_ORDER,
  SET_PROCESSING_ORDER,
} from "./constants";

export const setProduct = (product) => ({
  type: SET_PRODUCT,
  product,
});

export const setPaymentProcessor = (paymentProcessor) => ({
  type: SET_PAYMENT_PROCESSOR,
  paymentProcessor,
});

export const setLoadingProduct = (loadingProduct) => ({
  type: SET_LOADING_PRODUCT,
  loadingProduct,
});

export const setProcessingOrder = (processingOrder) => ({
  type: SET_PROCESSING_ORDER,
  processingOrder,
});

export const loadProduct = (handle) => ({
  type: LOAD_PRODUCT,
  handle,
});

export const createOrder = (payload) => ({
  type: CREATE_ORDER,
  payload,
});
