import { SET_CONNECTED_WALLET, SET_WRONG_NETWORK } from "./constants";

export const setConnectedWallet = (connectedWallet) => ({
  type: SET_CONNECTED_WALLET,
  connectedWallet,
});

export const setWrongNetwork = (wrongNetwork) => ({
  type: SET_WRONG_NETWORK,
  wrongNetwork,
});
