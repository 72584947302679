import AppHeader from "../containers/AppHeader";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import ProductSwapScreen from "../screens/ProductSwapScreen";
import PublishProductsScreen from "../screens/PublishProductsScreen";
import React, { useEffect } from "react";
import ShopScreen from "../screens/ShopScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import PayVendorScreen from "../screens/PayVendorScreen";
import OrderHistoryPage from "../screens/OrderHistory";
import AddPaymentProcessorScreen from "../screens/AddPaymentProcessorScreen";
import CreateProductFromNFT from "../screens/CreateProductFromNFT";
import CreateProductViewSampleScreen from "../screens/CreateProductViewSampleScreen";
import RedeemThreadTokensScreen from "../screens/RedeemThreadTokensScreen";
import CreateProductSelectType from "../screens/CreateProductSelectType";
import WalletShopScreen from "../screens/WalletShopScreen";
import HomeScreen from "../screens/HomeScreen";
import Footer from "../containers/Footer";
import HowItWorksScreen from "../screens/HowItWorksScreen";
import CreateNFTScreen from "../screens/CreateNFTScreen";
import CreateProductPublishScreen from "../screens/CreateProductPublishScreen";
import PrintfulCatalogProduct from "../models/PrintfulCatalogProduct";

const NLotRouter = () => {
  return (
    <Router>
      <AppHeader />
      <div style={{ position: "relative" }}>
        <video
          id="bg-video"
          loop
          autoPlay
          src={require("../Assets/COD_CS_Hero.mp4")}
        />
        <ScrollToTop />
        <div className="router-container">
          <Switch>
            <Route exact path="/">
              <HomeScreen />
            </Route>
            <Route exact path="/shop">
              <ShopScreen />
            </Route>
            <Route
              render={(e) => (
                <ProductSwapScreen handle={e.match.params.handle} />
              )}
              exact
              path="/products/:handle"
            />
            <Route exact path="/publish">
              <PublishProductsScreen />
            </Route>
            <Route exact path="/pay-vendor">
              <PayVendorScreen />
            </Route>
            <Route exact path="/wallet">
              <WalletShopScreen />
            </Route>
            <Route exact path="/order-history">
              <OrderHistoryPage />
            </Route>
            <Route exact path="/add-payment-token">
              <AddPaymentProcessorScreen />
            </Route>
            <Route exact path="/create-product/select-nft">
              <CreateProductFromNFT />
            </Route>
            <Route exact path="/create-product/select-product">
              <CreateProductSelectType />
            </Route>
            <Route exact path="/redeem-thread-tokens">
              <RedeemThreadTokensScreen />
            </Route>
            <Route exact path="/how-it-works">
              <HowItWorksScreen />
            </Route>
            <Route exact path="/create-nft">
              <CreateNFTScreen />
            </Route>
            <Route
              render={(e) => {
                const {
                  nft,
                  selectedProduct,
                  catalogProduct,
                  dimOverrides,
                  printfulProduct,
                  printFile,
                  variants,
                  mockUp,
                } = e.location.state;

                return (
                  <CreateProductPublishScreen
                    catalogProduct={catalogProduct}
                    mockUp={mockUp}
                    nft={nft}
                    printFile={printFile}
                    printfulProduct={printfulProduct}
                    selectedProduct={selectedProduct}
                    variants={variants}
                    dimOverrides={dimOverrides}
                    initialProduct={new PrintfulCatalogProduct(catalogProduct)}
                  />
                );
              }}
              exact
              path="/create-product/publish-product"
            />
            <Route
              render={(e) => (
                <CreateProductViewSampleScreen
                  nft={e.location.state.nft}
                  selectedProduct={e.location.state.selectedProduct}
                  initialProduct={
                    new PrintfulCatalogProduct(e.location.state.catalogProduct)
                  }
                />
              )}
              exact
              path="/create-product/edit-sample"
            />
            <Route component={NotFoundScreen} />
          </Switch>
        </div>
      </div>
      <Footer />
    </Router>
  );
};

const ScrollToTop = withRouter(({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
});

export default NLotRouter;
