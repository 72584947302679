import {
  SET_ERC20_CONTRACT,
  SET_THREAD_DAO_CONTRACT,
  SET_THREAD_TOKEN,
  SET_THREADSWAP_721,
} from "./constants";

export const setThreadDaoContract = (threadDaoContract) => ({
  type: SET_THREAD_DAO_CONTRACT,
  threadDaoContract,
});

export const setERC20Contract = (erc20Contract) => ({
  type: SET_ERC20_CONTRACT,
  erc20Contract,
});

export const setThreadToken = (threadToken) => ({
  type: SET_THREAD_TOKEN,
  threadToken,
});

export const setThreadSwap721 = (threadswap721) => ({
  type: SET_THREADSWAP_721,
  threadswap721,
});
