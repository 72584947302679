import React from "react";
import { Container } from "@mui/material";
import CreateProductStepper from "../../containers/CreateProductStepper";
import SelectPrintfulProduct from "../../containers/SelectPrintfulProduct";
import { useHistory } from "react-router-dom";

const CreateProductSelectType = () => {
  const history = useHistory();

  return (
    <Container>
      <CreateProductStepper activeStep={1} />
      <SelectPrintfulProduct
        onItemSelected={(item) => {
          history.push({
            pathname: "/create-product/edit-sample",
            state: { ...history.location.state, catalogProduct: item },
          });
        }}
      />
    </Container>
  );
};

export default CreateProductSelectType;
