import React, { useState } from "react";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MainDrawer from "../MainDrawer";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { makeSelectConnectedWallet } from "../ConnectedWalletWrapper/selectors";
import { connect } from "react-redux";
import ThreadTokenBalanceButton from "../ThreadTokenBalanceButton";
import ConnectedWalletButton from "../ConnectedWalletButton";

const AppHeader = ({ connectedWallet }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        style={{ padding: "15px 0" }}
        elevation={0}
        id="app-header"
        position="static"
      >
        <Toolbar>
          <Link
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
              fontSize: 28,
            }}
            to="/"
          >
            <Typography color="primary" variant="h5">
              THREAD
            </Typography>
            <Typography
              style={{
                marginTop: -17,
                textShadow: "0px -3px 0px #0e0315",
              }}
            >
              SWAP
            </Typography>
          </Link>
          <Link style={{ marginLeft: 36 }} to="/shop">
            <Typography variant="h5">STORE</Typography>
          </Link>
          <div style={{ flexGrow: 1 }} />
          <Box
            id="header-buttons"
            style={{
              width: 400,
              flexDirection: "row",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              <Grid item xs={6}>
                <ThreadTokenBalanceButton connectedWallet={connectedWallet} />
              </Grid>

              <Grid item xs={6}>
                <ConnectedWalletButton connectedWallet={connectedWallet} />
              </Grid>
            </Grid>
            <IconButton
              style={{ marginRight: -15 }}
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              size="large"
              edge="start"
              aria-label="menu"
              sx={{ mx: 2 }}
            >
              <MenuIcon style={{ fontSize: "2.2rem" }} color="primary" />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <MainDrawer
        toggleClose={() => setIsDrawerOpen(false)}
        isDrawerOpen={isDrawerOpen}
      />
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  connectedWallet: makeSelectConnectedWallet(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
