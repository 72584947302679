import { createSelector } from "reselect";

const selectContracts = (state) => state.contracts;

const makeSelectThreadDaoContract = () =>
  createSelector(
    selectContracts,

    (contractState) => contractState.threadDaoContract
  );

const makeSelectERC20Contract = () =>
  createSelector(
    selectContracts,

    (contractState) => contractState.erc20Contract
  );

const makeSelectThreadToken = () =>
  createSelector(
    selectContracts,

    (contractState) => contractState.threadToken
  );

const makeSelectThreadswap721 = () =>
  createSelector(
    selectContracts,

    (contractState) => contractState.threadswap721
  );

export {
  makeSelectThreadDaoContract,
  makeSelectERC20Contract,
  makeSelectThreadToken,
  makeSelectThreadswap721,
};
