import { createSelector } from "reselect";

const selectConnectedWallet = (state) => state.connectedWallet;

const makeSelectConnectedWallet = () =>
  createSelector(
    selectConnectedWallet,

    (contractState) => contractState.connectedWallet
  );

const makeSelectWrongNetwork = () =>
  createSelector(
    selectConnectedWallet,

    (contractState) => contractState.wrongNetwork
  );

export { makeSelectConnectedWallet, makeSelectWrongNetwork };
