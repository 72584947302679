import React from "react";
import "../../App.css";
import Router from "../../router";
import ConnectedWalletWrapper from "../ConnectedWalletWrapper";
import Web3Wrapper from "../Web3Wrapper";
import ContractsWrapper from "../ContractsWrapper";
import LightBox from "../LightBox";
import PaymentProcessorsWrapper from "../PaymentProcessorsWrapper";

const App = () => {
  return (
    <div className="App">
      <Web3Wrapper>
        <ContractsWrapper>
          <ConnectedWalletWrapper>
            <PaymentProcessorsWrapper>
              <LightBox />
              <Router />
            </PaymentProcessorsWrapper>
          </ConnectedWalletWrapper>
        </ContractsWrapper>
      </Web3Wrapper>
    </div>
  );
};

export default App;
