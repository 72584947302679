import React from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { makeSelectLightBoxImages } from "./selectors";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const LightBox = ({ images }) => {
  if (!images || images.size === 0) return null;

  return (
    <SimpleReactLightbox>
      <SRLWrapper>
        <a href="dkjfklsd">
          <img
            src="https://cdn.shopify.com/s/files/1/0611/3853/4630/products/unisex-staple-t-shirt-black-heather-front-619bf9b8998cf.jpg?v=1637611968"
            alt="Umbrella"
          />
        </a>
      </SRLWrapper>
    </SimpleReactLightbox>
  );
};

const mapStateToProps = createStructuredSelector({
  images: makeSelectLightBoxImages(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LightBox);
