import { fromJS } from "immutable";
import {
  SET_ERC20_CONTRACT,
  SET_THREAD_DAO_CONTRACT,
  SET_THREAD_TOKEN,
  SET_THREADSWAP_721,
} from "./constants";

const initialState = fromJS({
  threadDaoContract: null,
  erc20Contract: null,
  threadToken: null,
  threadswap721: null,
});

function contractsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_THREAD_DAO_CONTRACT:
      return {
        ...state,
        threadDaoContract: action.threadDaoContract,
      };

    case SET_ERC20_CONTRACT:
      return {
        ...state,
        erc20Contract: action.erc20Contract,
      };

    case SET_THREAD_TOKEN:
      return {
        ...state,
        threadToken: action.threadToken,
      };

    case SET_THREADSWAP_721:
      return {
        ...state,
        threadswap721: action.threadswap721,
      };

    default:
      return state;
  }
}

export { contractsReducer };
