import { createSelector } from "reselect";

const selectContracts = (state) => state.web3;

const makeSelectWeb3 = () =>
  createSelector(
    selectContracts,

    (web3) => web3.web3
  );

const makeSelectProvider = () =>
  createSelector(
    selectContracts,

    (web3) => web3.provider
  );

const makeSelectChainId = () =>
  createSelector(
    selectContracts,

    (web3) => web3.chainId
  );

export { makeSelectWeb3, makeSelectProvider, makeSelectChainId };
