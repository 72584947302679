import React from "react";
import { Container, Typography } from "@mui/material";

const NotFoundScreen = () => {
  return (
    <Container>
      <Typography color="primary" variant="h3" className="textShadow">
        404
      </Typography>
      <Typography>ngmi</Typography>
    </Container>
  );
};

export default NotFoundScreen;
