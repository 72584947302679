import React from "react";
import { Box, Stepper, Step, StepLabel, Typography } from "@mui/material";

const CreateProductStepper = ({ activeStep, createMode }) => {
  const steps = [
    `${createMode ? "Create" : "Select"} NFT`,
    "Select Product",
    "Edit Product",
    "Publish Product",
  ];
  return (
    <Box
      sx={{ width: "100%", maxWidth: 700, margin: "0 auto", marginBottom: 5 }}
    >
      <Typography paragraph variant="h5">
        CREATE PRODUCT
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel color="red">
              <Typography style={{ color: "#bfbfbf" }}>{label}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CreateProductStepper;
