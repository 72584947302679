import { useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { makeSelectConnectedWallet } from "../ConnectedWalletWrapper/selectors";
import { makeSelectWeb3 } from "../Web3Wrapper/selectors";
import { connect } from "react-redux";
import { setPaymentProcessors } from "./actions";
import { makeSelectThreadDaoContract } from "../ContractsWrapper/selectors";

const PaymentProcessorsWrapper = ({
  children,
  connectedWallet,
  web3,
  dispatch,
  threadDaoInstance,
}) => {
  useEffect(() => {
    if (web3 && connectedWallet && threadDaoInstance) {
      (async () => {
        const _processors = await threadDaoInstance.methods
          .listPaymentProcessors()
          .call();

        dispatch(setPaymentProcessors(_processors));
      })();
    }
  }, [web3, connectedWallet, threadDaoInstance]);

  return children;
};

const mapStateToProps = createStructuredSelector({
  connectedWallet: makeSelectConnectedWallet(),

  web3: makeSelectWeb3(),

  threadDaoInstance: makeSelectThreadDaoContract(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentProcessorsWrapper);
