import React, { useEffect, useState } from "react";
import {
  Button,
  CardActions,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
} from "@mui/material";
import PrintfulClient from "../../clients/PrintfulClient";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { makeSelectThreadDaoContract } from "../../containers/ContractsWrapper/selectors";
import { makeSelectConnectedWallet } from "../../containers/ConnectedWalletWrapper/selectors";
import { makeSelectWeb3 } from "../../containers/Web3Wrapper/selectors";
import CreateProductStepper from "../../containers/CreateProductStepper";
import ColorOption from "../../components/ColorOption";
import TransformArtworkDialog from "../../containers/TransformArtworkDialog";
import SelectProductDialog from "../../containers/SelectProductDialog";
import { Link, useHistory } from "react-router-dom";
import { AutoAwesome } from "@mui/icons-material";

const CreateProductViewSampleScreen = ({ nft, initialProduct }) => {
  const [catalogProduct, setCatalogProduct] = useState(initialProduct);
  const [loading, setLoading] = useState(false);
  const [isSelectingProduct, setIsSelectingProduct] = useState(false);
  const [isTransformingArtwork, setIsTransformingArtwork] = useState(false);
  const [printFile, setPrintFile] = useState(null);
  const [printfulProduct, setPrintfulProduct] = useState(null);
  const [mockUps, setMockUps] = useState([]);
  const [colorOptions, setColorOptions] = useState([]);
  const [variants, setVariants] = useState([]);
  const [ohmCount, setOhmCount] = useState(3);
  const [dimOverrides, setDimOverrides] = useState(null);

  const history = useHistory();

  useEffect(() => {
    createSampleAsync();
  }, [nft, catalogProduct, dimOverrides]);

  useEffect(() => {
    if (!variants.length) return;

    generateSample();
  }, [variants]);

  const createSampleAsync = async () => {
    setLoading(true);

    const [printfulProduct, pfRes] = await Promise.all([
      PrintfulClient.fetchPrintfulProductById(catalogProduct.id),
      PrintfulClient.fetchPrintFilesById(catalogProduct.id),
    ]);

    setPrintfulProduct(printfulProduct);

    setPrintFile(pfRes.result.printfiles[0]);

    setColorOptions(printfulProduct.getColorOptions());

    setVariants(printfulProduct.getDefaultVariants());
  };

  const generateSample = async () => {
    try {
      setLoading(true);

      const params = await catalogProduct.getSampleParams({
        variant_ids: variants,
        img: nft.image,
        printFile,
        o_left: dimOverrides ? dimOverrides.x : undefined,
        o_top: dimOverrides ? dimOverrides.y : undefined,
        o_width: dimOverrides ? dimOverrides.width : undefined,
        o_height: dimOverrides ? dimOverrides.height : undefined,
      });

      const res = await PrintfulClient.fetchMockUpAsync(
        params,
        catalogProduct.id,
        () => {
          setOhmCount(ohmCount + 3);
        }
      );
      setMockUps(res);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const onProductSelected = (item) => {
    setIsSelectingProduct(false);
    setCatalogProduct(item);
  };

  console.log(initialProduct);

  return (
    <Container maxWidth="lg">
      {isTransformingArtwork && (
        <TransformArtworkDialog
          onComplete={(params) => {
            setIsTransformingArtwork(false);
            setDimOverrides(params);
          }}
          printFile={printFile}
          artwork={nft.image}
          printfulProduct={catalogProduct}
          onClose={() => setIsTransformingArtwork(false)}
        />
      )}
      {isSelectingProduct && (
        <SelectProductDialog
          onClose={() => setIsSelectingProduct(false)}
          onSelect={onProductSelected}
        />
      )}
      <CreateProductStepper activeStep={2} />
      <div>
        <Grid container spacing={8}>
          <Grid item xs={0} sm={3} />
          <Grid item xs={12} sm={6}>
            {loading && (
              <div className="mockup-loading">
                <CircularProgress size={99} />
                <br />
                <Typography>CREATING SAMPLE</Typography>
              </div>
            )}
            {!loading && (
              <ImageList sx={{ width: "100%" }} cols={mockUps.length}>
                {mockUps.map((mockUp) => (
                  <ImageListItem sx={{ width: "100%" }} key={mockUp.mockup_url}>
                    <img alt={mockUp.mockup_url} src={mockUp.mockup_url} />
                  </ImageListItem>
                ))}
              </ImageList>
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            {!loading && (
              <Stack spacing={2} style={{ textAlign: "left" }}>
                {printFile && (
                  <div>
                    <Typography>Resize or move image</Typography>
                    <IconButton
                      style={{ backgroundColor: "white" }}
                      onClick={() => setIsTransformingArtwork(true)}
                    >
                      <AutoAwesome color="primary" />
                    </IconButton>
                  </div>
                )}
                {printFile && (
                  <div onClick={() => setIsSelectingProduct(true)}>
                    <Typography>Change Product</Typography>
                    <img
                      className="sample-option"
                      src={initialProduct.image}
                      alt="p-image"
                    />
                  </div>
                )}
                <div>
                  <Typography>Available product colors:</Typography>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {colorOptions.map((option) => (
                      <ColorOption
                        option={option}
                        key={option.color_code}
                        onSelect={(_variants) => {
                          setVariants(_variants);
                        }}
                      />
                    ))}
                  </div>
                </div>
                <Link
                  to={{
                    pathname: "/create-product/publish-product",
                    state: {
                      nft,
                      printfulProduct,
                      printFile,
                      dimOverrides,
                      selectedProduct: catalogProduct,
                      variants,
                      catalogProduct,
                      mockUp:
                        mockUps && mockUps.length > 0
                          ? mockUps[0].mockup_url
                          : "",
                    },
                  }}
                >
                  <Button fullWidth color="primary" variant="contained">
                    NEXT STEP
                  </Button>
                </Link>
              </Stack>
            )}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  threadDaoInstance: makeSelectThreadDaoContract(),

  connectedWallet: makeSelectConnectedWallet(),

  web3: makeSelectWeb3(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProductViewSampleScreen);
