import { fromJS } from "immutable";
import { SET_PAYMENT_PROCESSORS } from "./constants";

const initialState = fromJS({
  paymentProcessors: [],
});

function paymentProcessorsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PAYMENT_PROCESSORS:
      return {
        ...state,
        paymentProcessors: action.paymentProcessors,
      };

    default:
      return state;
  }
}

export { paymentProcessorsReducer };
