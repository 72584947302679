import { fromJS } from "immutable";
import { SET_CONNECTED_WALLET, SET_WRONG_NETWORK } from "./constants";

const initialState = fromJS({
  connectedWallet: null,
  wrongNetwork: false,
});

function connectedWalletReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CONNECTED_WALLET:
      return {
        ...state,
        connectedWallet: action.connectedWallet,
      };

    case SET_WRONG_NETWORK:
      return {
        ...state,
        wrongNetwork: action.wrongNetwork,
      };

    default:
      return state;
  }
}

export { connectedWalletReducer };
