import { Container } from "@mui/material";
import ListProductTab from "../../containers/ListProductTab";
import React, { useState } from "react";
import PrintfulClient from "../../clients/PrintfulClient";
import { createStructuredSelector } from "reselect";
import { makeSelectThreadDaoContract } from "../../containers/ContractsWrapper/selectors";
import { makeSelectConnectedWallet } from "../../containers/ConnectedWalletWrapper/selectors";
import { makeSelectWeb3 } from "../../containers/Web3Wrapper/selectors";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import CreateProductStepper from "../../containers/CreateProductStepper";

const CreateProductPublishScreen = ({
  web3,
  dimOverrides,
  variants,
  nft,
  mockUp,
  catalogProduct,
  printFile,
  printfulProduct,
  connectedWallet,
  threadDaoInstance,
}) => {
  const history = useHistory();

  const handleCreateProductAsync = async ({ title, retailPrice, cost }) => {
    const res = await PrintfulClient.createProduct({
      thumbnail: mockUp,
      img: nft.image,
      retail_price: retailPrice,
      name: title,
      variants,
      catalogProduct,
      printFile,
      o_left: dimOverrides ? dimOverrides.x : undefined,
      o_top: dimOverrides ? dimOverrides.y : undefined,
      o_width: dimOverrides ? dimOverrides.width : undefined,
      o_height: dimOverrides ? dimOverrides.height : undefined,
    });

    if (res.error) {
      throw new Error(res.message);
    }

    const { result } = res;

    web3.eth
      .sendTransaction({
        to: threadDaoInstance._address,
        from: connectedWallet,
        data: threadDaoInstance.methods
          .mintProductFromNFT(
            title,
            retailPrice * 100,
            cost * 100,
            result.id,
            result.thumbnail_url,
            nft.token_address,
            parseInt(nft.token_id)
          )
          .encodeABI(),
      })
      .on("receipt", () => {
        history.push(`/products/${result.id}`);
      });
  };

  return (
    <Container>
      <CreateProductStepper activeStep={3} />
      <Container maxWidth="sm">
        <ListProductTab
          connectedWallet={connectedWallet}
          onCreate={handleCreateProductAsync}
          variants={variants}
          thumbnail={mockUp}
          printfulProduct={printfulProduct}
          catalogProduct={catalogProduct}
          nft={nft}
        />
      </Container>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  threadDaoInstance: makeSelectThreadDaoContract(),

  connectedWallet: makeSelectConnectedWallet(),

  web3: makeSelectWeb3(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProductPublishScreen);
