const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

class CoinGeckoClient {
  static getAvalancheToUsdAsync() {
    return fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=avalanche-2&vs_currencies=usd",
      {
        headers,
      }
    ).then((res) => res.json());
  }
}

export default CoinGeckoClient;
