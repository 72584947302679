import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Grid,
  LinearProgress,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import PrintfulClient from "../../clients/PrintfulClient";
import GridProduct from "../GridProduct";

const StoreGrid = ({ limit }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchProductsAsync();
  }, []);

  const fetchProductsAsync = async () => {
    try {
      setLoading(true);

      const _products = await PrintfulClient.fetchProducts({ limit });

      setProducts(_products.result);

      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid id="shop-grid" container spacing={2}>
      {loading && <LinearProgress variant="indeterminate" color="primary" />}
      {products.map((product) => (
        <Grid key={product.id} item xs={12} sm={4}>
          <GridProduct product={product} />
        </Grid>
      ))}
    </Grid>
  );
};

export default StoreGrid;
