import { createSelector } from "reselect";

const selectProductPage = (state) => state.productPage;

const makeSelectLoadingProduct = () =>
  createSelector(
    selectProductPage,

    (contractState) => contractState.loadingProduct
  );

const makeSelectProduct = () =>
  createSelector(
    selectProductPage,

    (contractState) => contractState.product
  );

const makeSelectPaymentProcessor = () =>
  createSelector(
    selectProductPage,

    (contractState) => contractState.paymentProcessor
  );

const makeSelectProcessingOrder = () =>
  createSelector(
    selectProductPage,

    (contractState) => contractState.processingOrder
  );

export {
  makeSelectProduct,
  makeSelectLoadingProduct,
  makeSelectPaymentProcessor,
  makeSelectProcessingOrder,
};
