import { Box, Tab, Tabs } from "@mui/material";
import CatalogProductSelectorRow from "../CatalogProductSelectorRow";
import React, { useState } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const SelectPrintfulProduct = ({ onItemSelected }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
        >
          <Tab label="T-Shirts" style={{ color: "white" }} />
          <Tab label="Hoodies" style={{ color: "white" }} />
          <Tab label="Hats" style={{ color: "white" }} />
          <Tab label="Mugs" style={{ color: "white" }} />
          <Tab label="Phone Cases" style={{ color: "white" }} />
          <Tab label="Posters" style={{ color: "white" }} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <CatalogProductSelectorRow
          onItemSelected={onItemSelected}
          filter={(item) => item.type_name === "T-Shirt"}
          type="T-Shirt"
          title="T-SHIRTS"
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CatalogProductSelectorRow
          onItemSelected={onItemSelected}
          filter={(item) => item.type_name === "Hoodie"}
          type="T-Shirt"
          title="HOODIES"
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CatalogProductSelectorRow
          onItemSelected={onItemSelected}
          filter={(item) => item.type_name === "Hat"}
          type="EMBROIDERY"
          title="HATS"
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <CatalogProductSelectorRow
          onItemSelected={onItemSelected}
          filter={() => true}
          type="MUG"
          title="MUGS"
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CatalogProductSelectorRow
          onItemSelected={onItemSelected}
          filter={() => true}
          type="PHONE-CASE"
          title="PHONE CASES"
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <CatalogProductSelectorRow
          onItemSelected={onItemSelected}
          filter={() => true}
          type="POSTER"
          title="POSTERS"
        />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <CatalogProductSelectorRow
          onItemSelected={onItemSelected}
          filter={() => true}
          type="FRAMED-POSTER"
          title="FRAMED POSTERS"
        />
      </TabPanel>
    </div>
  );
};

export default SelectPrintfulProduct;
