const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const host = "https://us-central1-thread-dao.cloudfunctions.net/app";
// const host = "http://localhost:5001/thread-dao/us-central1/app";

class FunctionClient {
  static createDraftOrderAsync(payload) {
    return fetch(`${host}/create-draft`, {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    }).then(async (res) => {
      if (res.status >= 400) {
        const err = await res.json();

        throw new Error(err.message);
      }

      return res.json();
    });
  }

  static completeDraft(draftOrder, hash) {
    headers.Authorization = hash;

    return fetch(`${host}/complete-draft/${draftOrder.id}`, {
      method: "PUT",
      headers,
    }).then(async (res) => {
      if (res.status >= 400) {
        const err = await res.json();

        throw new Error(err.err);
      }

      return res.json();
    });
  }

  static fetchOrders(wallet) {
    return fetch(`${host}/orders/${wallet}`, {
      method: "GET",
      headers,
    }).then((res) => res.json());
  }

  static fetchUriProxy(uri) {
    return fetch(`${host}/token-uri-proxy?uri=${encodeURI(uri)}`, {
      method: "GET",
      headers,
    }).then((res) => res.json());
  }

  static fetchNFTS(payload) {
    return fetch(
      `https://api.nftrade.com/api/v1/tokens?address=${payload.address}&connectedChainId=${payload.network}&limit=8`,
      {
        method: "GET",
        headers,
        // body: JSON.stringify(payload),
      }
    ).then((res) => res.json());
  }

  static updateDraft(draftOrder, hash) {
    headers.Authorization = hash;

    return fetch(`${host}/update-draft/${draftOrder.id}`, {
      method: "PUT",
      headers,
    }).then((res) => res.json());
  }

  static updateNFTImage(file) {
    const body = new FormData();

    body.append("filefield", file);

    console.log(body);

    return fetch(`${host}/nft`, {
      method: "POST",
      body,
      headers: {
        //"content-type": `multipart/form-data; boundary=${body._boundary}`,
      },
    }).then((res) => res.json());
  }
}

export default FunctionClient;
