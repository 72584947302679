import React from "react";

const ColorOption = ({ option, onSelect }) => {
  return (
    <div
      key={option.color_code}
      style={{
        backgroundColor: option.color_code,
        height: 40,
        width: 40,
        marginRight: 8,
        marginBottom: 8,
        borderRadius: "50%",
        cursor: "pointer",
      }}
      onClick={() => onSelect(option.variants.map((v) => v.id))}
    />
  );
};

export default ColorOption;
