import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect } from "react";

const GridProduct = ({ product }) => {
  const history = useHistory();

  // useEffect(() => {
  //   (async () => {})();
  // }, [product]);

  return (
    <Card
      elevation={0}
      style={{
        backgroundColor: "transparent",
      }}
    >
      <CardMedia>
        <Link to={`/products/${product.id}`}>
          <img
            style={{ borderRadius: 5 }}
            alt={product.name}
            src={product.thumbnail_url}
          />
        </Link>
      </CardMedia>
      <CardContent>
        <Typography color="primary">{product.name}</Typography>
      </CardContent>
      <CardActions>
        <Button
          onClick={() => {
            history.push(`/products/${product.id}`);
          }}
          fullWidth
          variant="outlined"
        >
          <Typography>PURCHASE</Typography>
        </Button>
      </CardActions>
    </Card>
  );
};

export default GridProduct;
