import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Card,
  CardHeader,
  Typography,
} from "@mui/material";
import { createStructuredSelector } from "reselect";
import { makeSelectConnectedWallet } from "../../containers/ConnectedWalletWrapper/selectors";
import { makeSelectThreadDaoContract } from "../../containers/ContractsWrapper/selectors";
import { connect } from "react-redux";
import { makeSelectWeb3 } from "../../containers/Web3Wrapper/selectors";
import ThreadDaoContract from "../../contracts/fuji/ThreadDaoV2.json";
import ERC20Contract from "../../contracts/localhost/MockToken.json";

const AddPaymentProcessorScreen = ({
  web3,
  threadDaoInstance,
  connectedWallet,
}) => {
  const [name, setName] = useState("");
  const [contract, setContract] = useState("");
  const [priceOracle, setPriceOracle] = useState("");
  const [decimals, setDecimals] = useState("18");

  useEffect(() => {
    if (web3 && contract) {
      (async () => {
        const erc20ContractInstance = new web3.eth.Contract(
          ERC20Contract.abi,
          contract
        );
        setDecimals(await erc20ContractInstance.methods.decimals().call());
        setName(await erc20ContractInstance.methods.name().call());
      })(); // 0x0b9d5d9136855f6fec3c0993fee6e9ce8a297846
    }
  }, [contract]);

  const handleAddPaymentProcessor = async (e) => {
    e.preventDefault();

    await threadDaoInstance.methods
      .addPaymentProcessor(contract, name, priceOracle, parseInt(decimals, 10))
      .send(
        {
          from: connectedWallet,
        },
        function (err, res) {
          if (!err) {
            alert("Success");
          } else {
            console.log(err);
          }
        }
      );
  };

  return (
    <Container maxWidth="md">
      <Card elevation={0} className="product-card">
        <Typography variant="h5" color="primary">
          Add Payment Processor
        </Typography>
        <form
          style={{ display: "flex", flexDirection: "column", padding: 15 }}
          onSubmit={handleAddPaymentProcessor}
        >
          <TextField
            label="Name"
            color="primary"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <br />
          <TextField
            label="Token Contract"
            color="primary"
            variant="outlined"
            value={contract}
            onChange={(e) => setContract(e.target.value)}
          />
          <br />
          <TextField
            label="ChainLink Price Oracle"
            color="primary"
            variant="outlined"
            value={priceOracle}
            onChange={(e) => setPriceOracle(e.target.value)}
          />
          <br />
          <TextField
            label="Decimals"
            color="primary"
            variant="outlined"
            type="number"
            value={decimals}
            onChange={(e) => setDecimals(e.target.value)}
          />
          <br />
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </form>
      </Card>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  web3: makeSelectWeb3(),

  threadDaoInstance: makeSelectThreadDaoContract(),

  connectedWallet: makeSelectConnectedWallet(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPaymentProcessorScreen);
