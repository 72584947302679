import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#62bfb1",
      contrastText: "#fff",
    },
    secondary: {
      main: "#db1f2e",
    },
    action: {
      disabledBackground: "#fe3f44",
    },
  },
  overrides: {
    MuiButton: {
      disabled: {
        backgroundColor: "red",
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderColor: "red",
        },
      },
    },
  },
  textField: {},
  typography: {
    fontFamily: [
      "VCROSDMono",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    palette: {
      textPrimary: {
        main: "white",
      },
    },
  },
});

export default theme;
