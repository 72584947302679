import { put, takeLatest, all } from "redux-saga/effects";
import {
  setERC20Contract,
  setThreadDaoContract,
  setThreadToken,
  setThreadSwap721,
} from "./actions";
import { SET_CHAIN, SET_WEB3 } from "../Web3Wrapper/constants";
import ThreadDaoContract from "../../contracts/avalanche/ThreadSwap.json";
import ERC20Contract from "../../contracts/localhost/MockToken.json";
import ThreadToken from "../../contracts/avalanche/ThreadToken.json";
import Threadswap721 from "../../contracts/avalanche/ThreadSwap721.json";
import { setWrongNetwork } from "../ConnectedWalletWrapper/actions";

export function* connectContractSaga({ web3 }) {
  if (!web3) {
    yield put(setThreadDaoContract(null));
    return;
  }

  try {
    const threadDaoContractInstance = new web3.eth.Contract(
      ThreadDaoContract.abi,
      ThreadDaoContract.address
    );

    yield put(setThreadDaoContract(threadDaoContractInstance));

    const threadswap721ContractInstance = new web3.eth.Contract(
      Threadswap721.abi,
      Threadswap721.address
    );

    yield put(setThreadSwap721(threadswap721ContractInstance));
  } catch (error) {
    // Catch any errors for any of the above operations.
    alert(`Failed to load ThreadDAO Factory Contract.`);
    console.error(error);
  }
}

export function* connectThreadTokenSaga({ web3 }) {
  if (!web3) {
    yield put(setThreadToken(null));
    return;
  }

  try {
    const threadTokenInstance = new web3.eth.Contract(
      ThreadToken.abi,
      ThreadToken.address
    );

    yield put(setThreadToken(threadTokenInstance));
  } catch (error) {
    console.error(error);
  }
}

export function* connectErc20ContractSaga({ web3 }) {
  if (!web3) {
    yield put(setERC20Contract(null));
    return;
  }

  try {
    const erc20ContractInstance = new web3.eth.Contract(
      ERC20Contract.abi,
      ERC20Contract.address
    );

    yield put(setERC20Contract(erc20ContractInstance));
  } catch (error) {
    // Catch any errors for any of the above operations.
    alert(`Failed to load ThreadDAO Factory Contract.`);
    console.error(error);
  }
}

export function* checkNetworkSaga({ web3 }) {
  if (!web3) {
    yield put(setWrongNetwork(false));
    return;
  }

  const networkId = yield web3.eth.getChainId();

  yield put(setWrongNetwork(networkId !== 43114));
}

export function* connectContractWatcher() {
  yield takeLatest(SET_WEB3, connectContractSaga);
}

export function* connectERC20ContractWatcher() {
  yield takeLatest(SET_WEB3, connectErc20ContractSaga);
}

export function* checkNetworkWatcher() {
  yield takeLatest(SET_WEB3, checkNetworkSaga);
}

export function* connectThreadTokenWatcher() {
  yield takeLatest(SET_WEB3, connectThreadTokenSaga);
}

export function* setChainWatcher() {
  yield takeLatest(SET_CHAIN, checkNetworkSaga);
}

export default function* rootContractsSaga() {
  yield all([
    connectContractWatcher(),
    connectERC20ContractWatcher(),
    checkNetworkWatcher(),
    connectThreadTokenWatcher(),
    setChainWatcher(),
  ]);
}
