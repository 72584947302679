import {
  FETCH_THREAD_BALANCE,
  SET_THREAD_BALANCE,
  FETCH_ETH_BALANCE,
  SET_ETH_BALANCE,
} from "./constants";

export const setThreadBalance = (threadBalance) => ({
  type: SET_THREAD_BALANCE,
  threadBalance,
});

export const setEthBalance = (ethBalance) => ({
  type: SET_ETH_BALANCE,
  ethBalance,
});

export const fetchThreadBalance = () => ({
  type: FETCH_THREAD_BALANCE,
});

export const fetchEthBalance = () => ({
  type: FETCH_ETH_BALANCE,
});
